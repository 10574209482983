import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  div {
    table {
      min-width: 40vw;
    }
  }
`

export const ButtonContainer = styled.div`
  margin: 0.5em;
`

export const ButtonContainerTable = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;

  button {
    display: flex;
    align-items: center;
    gap: 10px;
  }
`

export const ContainerInputHorizontal = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
`
