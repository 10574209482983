import React, { useState, useEffect } from 'react'
import { FaArrowAltCircleRight } from 'react-icons/fa'
import { toast } from 'react-toastify'

import api from '../../services/apiv3'
import axios from 'axios'

const GsWave = () => {
    const [domains, setDomains] = useState([])
    const [vchDomain, setVchDomain] = useState('')

    const [users, setUsers] = useState([])
    const [vchUsername, setVchUsername] = useState('')

    const [userObject, setUserObject] = useState(null)
    const [inputGsWaveName, setInputGsWaveName] = useState('')
    const [inputSenha, setInputSenha] = useState('')
    const [inputDominio, setInputDominio] = useState('')
    const [inputPorta, setInputPorta] = useState('')
    const [inputEmail, setInputEmail] = useState('')

    const enviar = async () => {
        if (userObject && userObject.VCH_USERNAME && inputGsWaveName && inputSenha && inputDominio && inputEmail) {
            await axios.post('https://api.cloudcom.com.br/v3/', {
                usuario: userObject.VCH_USERNAME,
                ramal: inputGsWaveName,
                senha: inputSenha,
                dominio: inputDominio,
                porta: inputPorta,
                email: inputEmail,
                assinatura: 'cloud'
            })
            toast.success('QrCode enviado com sucesso')
        } else {
            toast.error('Erro de validação verifique todos os campos')
        }
    }

    const carregarDominios = async () => {
        const { data } = await api.get('/basix/domains')
        setDomains(data)
    }

    const carregarUsuarios = async () => {
        const { data } = await api.get('/basix/users', {
            params: {
                domain: vchDomain
            }
        })
        setUsers(data)
    }

    useEffect(() => {
        carregarDominios()
    }, [])

    useEffect(() => {
        if (vchDomain) {
            carregarUsuarios()

            setVchUsername('')
            setInputGsWaveName('')
            setInputSenha('')
            setInputDominio('')
            setInputPorta('')
        } else {
            setVchUsername('')
            setInputGsWaveName('')
            setInputSenha('')
            setInputDominio('')
            setInputPorta('')
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [vchDomain])

    useEffect(() => {
        if (vchUsername) {
            const tempUser = users.find(item => item.VCH_USERNAME === vchUsername)

            if (tempUser) {
                setInputGsWaveName(tempUser.VCH_NAME)
                setInputSenha(tempUser.VCH_REAL_PASSWORD ? tempUser.VCH_REAL_PASSWORD : '')
                setInputDominio(vchDomain)
                setInputPorta(tempUser.VCH_OUTBOUNDPROXYPORT ? tempUser.VCH_OUTBOUNDPROXYPORT : '')
                setUserObject(tempUser)
            } else {
                setInputGsWaveName('')
                setInputSenha('')
                setInputDominio('')
                setInputPorta('')
                setUserObject(null)
            }


        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [vchUsername])

    return (
        <div>
            <br />
            <div className="container">
                <div className="mb-3">
                    <label htmlFor="SelectDomain" className="form-label">Domínio</label>
                    <select id="SelectDomain" class={`form-select ${vchDomain ? '' : 'is-invalid'}`} placeholder="Dominio" value={vchDomain} onChange={e => setVchDomain(e.target.value)}>
                        <option value="">Selecione uma Domínio</option>
                        {domains && domains.map(domain => {
                            return (
                                <option value={domain.VCH_DOMAIN}>{domain.VCH_DOMAIN}</option>
                            )
                        })}
                    </select>
                </div>

                <form>
                    <fieldset>
                        <div className="mb-3">
                            <label htmlFor="SelectUsuario" className="form-label">Usuário</label>
                            <select id="SelectUsuario" class={`form-select ${vchUsername ? '' : 'is-invalid'}`} placeholder="Usuario" value={vchUsername} onChange={e => setVchUsername(e.target.value)}>
                                <option>Selecione um Usuário</option>
                                {users && users.map(user => {
                                    return (
                                        <option value={user.VCH_USERNAME}>{user.VCH_USERNAME} - {user.VCH_NAME}</option>
                                    )
                                })}
                            </select>
                        </div>

                        <div className="mb-3">
                            <label htmlFor="InputGsWaveName" className="form-label">Nome no GsWave</label>
                            <input type="text" id="InputGsWaveName" className={`form-control ${inputGsWaveName ? '' : 'is-invalid'}`} placeholder="Nome no GsWave" value={inputGsWaveName} onChange={e => setInputGsWaveName(e.target.value)} />
                        </div>

                        <div className="mb-3">
                            <label htmlFor="InputSenha" className="form-label">Senha</label>
                            <input type="text" id="InputSenha" className={`form-control ${inputSenha ? '' : 'is-invalid'}`} placeholder="Senha" value={inputSenha} onChange={e => setInputSenha(e.target.value)} disabled={inputSenha} />
                        </div>

                        <div className="mb-3">
                            <label htmlFor="InputDomain" className="form-label">Domínio</label>
                            <input type="text" id="InputDomain" className={`form-control ${inputDominio ? '' : 'is-invalid'}`} placeholder="Domínio" value={inputDominio} onChange={e => setInputDominio(e.target.value)} disabled />
                        </div>

                        <div className="mb-3">
                            <label htmlFor="InputPorta" className="form-label">Porta</label>
                            <input type="text" id="InputPorta" className={`form-control`} placeholder="Porta" value={inputPorta} onChange={e => setInputPorta(e.target.value)} disabled />
                        </div>

                        <div className="mb-3">
                            <label htmlFor="InputEmail" className="form-label">E-mails (Se houver mais de 1 separe por vírgula)</label>
                            <input type="text" id="InputEmail" className={`form-control ${inputEmail ? '' : 'is-invalid'}`} placeholder="E-mails" value={inputEmail} onChange={e => setInputEmail(e.target.value)} />
                        </div>
                    </fieldset>
                </form>

                <button type="button" className="btn btn-success" onClick={() => { enviar() }}>
                    Enviar <FaArrowAltCircleRight />
                </button>
            </div>
        </div>
    )
}

export default GsWave;
