import styled from 'styled-components'

export const Original = styled.span`
    font-size: 12px;
    color: red;
`;

export const Form = styled.form`
    legend {
        margin-top: 25px;
        margin-bottom: 50px;
    }
`;