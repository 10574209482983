import React, { useState, useEffect } from 'react'
import { toast } from 'react-toastify'
import { FaEdit, FaWindowClose, FaPlus } from 'react-icons/fa'

import api from '../../../services/apiv3'

import { ButtonContainer, TrCustom, DivFormCheck } from './styles'

const Zendesk_Usuarios = ({ match }) => {
  const { id_domain } = match.params

  const [users, setUsers] = useState([])
  const [allUsers, setAllUsers] = useState([])

  const [selectAddUser, setselectAddUser] = useState('')
  const [activeAddUser, setActiveAddUser] = useState(true)
  const [emailZendesk, setEmailZendesk] = useState('')
  const [subDominioZendesk, setSubDominioZendesk] = useState('')
  const [tokenZendesk, setTokenZendesk] = useState('')

  const [IDAlterar, setIDAlterar] = useState(0)
  const [activeAlterar, setActiveAlterar] = useState(true)
  const [usernameAlterar, setUsernameAlterar] = useState('')
  const [emailZendeskAlterar, setEmailZendeskAlterar] = useState('')
  const [subDominioZendeskAlterar, setSubDominioZendeskAlterar] = useState('')
  const [tokenZendeskAlterar, setTokenZendeskAlterar] = useState('')

  const [IDRemover, setIDRemover] = useState(0)
  const [usernameRemover, setUsernameRemover] = useState('')

  const buscarTodosUsuarios = async () => {
    try {
      const { data: domainData } = await api.get(`/basix/integracao/zendesk/domain/${id_domain}`)

      const { data } = await api.get(`/basix/users`, {
        params: {
          domain: domainData.domain
        }
      })

      setAllUsers(data)
    } catch (error) {
      toast.error(error.message)
    }
  }

  const buscarUsuarios = async () => {
    try {
      const { data } = await api.get(`/basix/integracao/zendesk/domain/${id_domain}/user`)
      setUsers(data)
    } catch (error) {
      toast.error(error.message)
    }
  }

  const adicionarUsuarios = async () => {
    try {
      await api.post(`/basix/integracao/zendesk/domain/${id_domain}/user`, {
        username: selectAddUser,
        active: activeAddUser,
        email_zendesk: emailZendesk,
        token_zendesk: subDominioZendesk,
        sub_domain_zendesk: tokenZendesk,
        fk_id_domain: Number(id_domain)
      })
      toast.success(`Usuário ${selectAddUser} adicionado`)
      document.getElementById('btnCloseModal').click();
      await buscarUsuarios()

      setTimeout(() => {
        setselectAddUser('')
        setActiveAddUser(true)
        setEmailZendesk('')
        setSubDominioZendesk('')
        setTokenZendesk('')
      }, 1)
    } catch (error) {
      if (error?.response?.data?.error) {
        toast.error(error.response.data.error)
      } else {
        toast.error(error.message)
      }
    }
  }

  const removerUsuario = async () => {
    try {
      await api.delete(`/basix/integracao/zendesk/domain/${id_domain}/user/${IDRemover}`)
      toast.success(`Usuário ${usernameRemover} removido`)
      document.getElementById('btnCloseModalRemover').click();
      await buscarUsuarios()

      setTimeout(() => {
        setIDRemover(0)
        setUsernameRemover('')
      }, 1)
    } catch (error) {
      if (error?.response?.data?.error) {
        toast.error(error.response.data.error)
      } else {
        toast.error(error.message)
      }
    }
  }

  const buscarDadosUsuario = async (userid) => {
    try {
      const { data } = await api.get(`/basix/integracao/zendesk/domain/${id_domain}/user/${userid}`)

      setEmailZendeskAlterar(data.email_zendesk)
      setSubDominioZendeskAlterar(data.sub_domain_zendesk)
      setTokenZendeskAlterar(data.token_zendesk)

      setTimeout(() => {
        document.getElementById('btn_abrir_modal_editar').click();
      }, 1)
    } catch (error) {
      if (error?.response?.data?.error) {
        toast.error(error.response.data.error)
      } else {
        toast.error(error.message)
      }
    }
  }

  const alterarUsuario = async () => {
    try {
      await api.put(`/basix/integracao/zendesk/domain/${id_domain}/user/${IDAlterar}`, {
        username: usernameAlterar,
        active: activeAlterar,
        email_zendesk: emailZendeskAlterar,
        token_zendesk: tokenZendeskAlterar,
        sub_domain_zendesk: subDominioZendeskAlterar,
        fk_id_domain: Number(id_domain)
      })

      toast.success(`Usuário ${usernameAlterar} alterado`)
      document.getElementById('btnCloseModalAlterar').click();
      await buscarUsuarios()

      setTimeout(() => {
        setIDAlterar(0)
        setActiveAlterar(true)
        setUsernameAlterar('')
        setEmailZendeskAlterar('')
        setSubDominioZendeskAlterar('')
        setTokenZendeskAlterar('')
      })
    } catch (error) {
      if (error?.response?.data?.error) {
        toast.error(error.response.data.error)
      } else {
        toast.error(error.message)
      }
    }
  }

  useEffect(() => {
    buscarUsuarios()
    buscarTodosUsuarios()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id_domain])

  return (
    <div className="container">
      <ButtonContainer>
        <button type="button" className="btn btn-primary" data-bs-toggle="modal" data-bs-target="#modal_adicionar"><FaPlus /> Adicionar</button>
      </ButtonContainer>
      <table className="table">
        <thead>
          <tr>
            <th scope="col" style={{ width: "10%" }}>ID</th>
            <th scope="col" style={{ width: "50%" }}>Usuário</th>
            <th scope="col" style={{ width: "10%" }}>Ativo</th>
            <th scope="col" style={{ width: "30%" }}></th>
          </tr>
        </thead>
        <tbody>
          {
            users.length > 0 && users.map(item => {
              return (
                <TrCustom key={item.id}>
                  <th scope="row">{item.id}</th>
                  <td>{item.username}</td>
                  <td>{item.active ? 'Sim' : 'Não'}</td>
                  <td className='buttons'>
                    <button type="button" className="btn btn-warning btn-sm" onClick={() => {
                      setIDAlterar(item.id)
                      setActiveAlterar(item.active)
                      setUsernameAlterar(item.username)

                      buscarDadosUsuario(item.id)
                    }}><FaEdit /> Alterar</button>{' '}
                    <button type="button" className="btn btn-danger btn-sm" data-bs-toggle="modal" data-bs-target="#modal_remover" onClick={() => {
                      setIDRemover(item.id)
                      setUsernameRemover(item.username)
                    }}><FaWindowClose /> Remover</button>

                    <button id='btn_abrir_modal_editar' type="button" className="btn btn-warning btn-sm" data-bs-toggle="modal" data-bs-target="#modal_alterar" style={{ display: "none" }}>BTN ABRIR</button>
                  </td>
                </TrCustom>
              )
            })
          }
        </tbody>
      </table>

      {/* Modal Adicionar */}
      <div className="modal fade" id="modal_adicionar" tabIndex="-1" aria-labelledby="modal_adicionar_label" aria-hidden="true">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="modal_adicionar_label">Adicionar Usuário</h1>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body">
              <div className="mb-3">
                <select className="form-select" aria-label="Default select example" value={selectAddUser} onChange={e => setselectAddUser(e.target.value)}>
                  <option value="">Selecione um usuário</option>
                  {allUsers && allUsers.filter(item => !(users.map(item2 => item2.username).includes(item.VCH_USERNAME))).map(item => {
                    return (
                      <option key={`${item.INT_USER_KEY}`} value={item.VCH_USERNAME}>{item.VCH_USERNAME}</option>
                    )
                  })}
                </select>
              </div>

              <DivFormCheck>
                <div className="form-check">
                  <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" checked={activeAddUser} onChange={e => setActiveAddUser(e.target.checked)} />
                  <label className="form-check-label" htmlFor="flexCheckDefault">
                    Ativo
                  </label>
                </div>
              </DivFormCheck>

              <div className="mb-3">
                <label htmlFor="inputUpdateDomainName" className="form-label">Email Zendesk</label>
                <input type="text" className="form-control" id="inputUpdateDomainName" value={emailZendesk} onChange={e => setEmailZendesk(e.target.value)} />
              </div>

              <div className="mb-3">
                <label htmlFor="inputUpdateDomainName" className="form-label">Sub Domínio Zendesk</label>
                <input type="text" className="form-control" id="inputUpdateDomainName" value={subDominioZendesk} onChange={e => setSubDominioZendesk(e.target.value)} />
              </div>

              <div className="mb-3">
                <label htmlFor="inputUpdateDomainName" className="form-label">Token Zendesk</label>
                <input type="text" className="form-control" id="inputUpdateDomainName" value={tokenZendesk} onChange={e => setTokenZendesk(e.target.value)} />
              </div>
            </div>

            <div className="modal-footer">
              <button id='btnCloseModal' type="button" className="btn btn-secondary" data-bs-dismiss="modal">Fechar</button>
              <button type="button" className="btn btn-primary" onClick={(() => { adicionarUsuarios() })}>Adicionar</button>
            </div>
          </div>
        </div>
      </div>

      {/* Modal Alterar */}
      <div className="modal fade" id="modal_alterar" tabIndex="-1" aria-labelledby="modal_alterar_label" aria-hidden="true">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="modal_alterar_label">Alterar Usuário</h1>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body">
              <div className="mb-3">
                <label htmlFor="inputUpdateDomainName" className="form-label">Usuário</label>
                <input type="text" className="form-control" id="inputUpdateDomainName" value={usernameAlterar} disabled />
              </div>

              <DivFormCheck>
                <div className="form-check">
                  <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" checked={activeAlterar} onChange={e => setActiveAlterar(e.target.checked)} />
                  <label className="form-check-label" htmlFor="flexCheckDefault">
                    Ativo
                  </label>
                </div>
              </DivFormCheck>

              <div className="mb-3">
                <label htmlFor="inputUpdateDomainName" className="form-label">Email Zendesk</label>
                <input type="text" className="form-control" id="inputUpdateDomainName" value={emailZendeskAlterar} onChange={e => setEmailZendeskAlterar(e.target.value)} />
              </div>

              <div className="mb-3">
                <label htmlFor="inputUpdateDomainName" className="form-label">Sub Domínio Zendesk</label>
                <input type="text" className="form-control" id="inputUpdateDomainName" value={subDominioZendeskAlterar} onChange={e => setSubDominioZendeskAlterar(e.target.value)} />
              </div>

              <div className="mb-3">
                <label htmlFor="inputUpdateDomainName" className="form-label">Token Zendesk</label>
                <input type="text" className="form-control" id="inputUpdateDomainName" value={tokenZendeskAlterar} onChange={e => setTokenZendeskAlterar(e.target.value)} />
              </div>
            </div>

            <div className="modal-footer">
              <button id='btnCloseModalAlterar' type="button" className="btn btn-secondary" data-bs-dismiss="modal">Fechar</button>
              <button type="button" className="btn btn-primary" onClick={(() => { alterarUsuario() })}>Alterar</button>
            </div>
          </div>
        </div>
      </div>

      {/* Modal Remover */}
      <div className="modal fade" id="modal_remover" tabIndex="-1" aria-labelledby="modal_remover_label" aria-hidden="true">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="modal_remover_label">Remover Usuário</h1>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body">
              <div className="mb-3">
                <label htmlFor="inputUpdateDomainName" className="form-label">Usuário</label>
                <input type="text" className="form-control" id="inputUpdateDomainName" value={usernameRemover} disabled />
              </div>
            </div>
            <div className="modal-footer">
              <button id='btnCloseModalRemover' type="button" className="btn btn-secondary" data-bs-dismiss="modal">Fechar</button>
              <button type="button" className="btn btn-danger" onClick={(() => { removerUsuario() })}>Remover</button>
            </div>
          </div>
        </div>
      </div>
    </div >
  )
}

export default Zendesk_Usuarios;
