import React, { useContext, useState, useEffect } from "react"
import { Link } from "react-router-dom"
import { toast } from "react-toastify"
import { FaBuilding, FaRegBuilding, FaPlus, FaMinus } from "react-icons/fa"

import api from "../../../services/api"
import { AuthContext } from "../../../context/AuthContext"
import { Container, Item } from "./styles"

const Dominios = () => {
    const { username } = useContext(AuthContext)
    const [dominios, setDominios] = useState([])
    const [dominiosTC, setDominiosTC] = useState([])
    const [busca, setBusca] = useState("")

    const listarDominios = async () => {
        try {
            const { data: domains } = await api.get("/timeclass/domain")
            setDominios(domains)

            const { data: domainsAdded } = await api.get("/timeclass/domain/added")
            setDominiosTC(domainsAdded)
        } catch (error) {
            if (error.response) {
                toast.error(error.response.data.error)
            } else {
                toast.error(error.message)
            }
        }
    }

    const adicionarDominio = async (dominio) => {
        try {
            await api.post(
                "/timeclass/domain",
                {
                    domain: dominio
                },
                {
                    headers: {
                        username
                    }
                }
            )
            listarDominios()
            toast.success(`Domínio ${dominio} adicionado com sucesso`)
        } catch (error) {
            if (error.response) {
                toast.error(error.response.data.error)
            } else {
                toast.error(error.message)
            }
        }
    }

    const removerDominio = async (dominio) => {
        try {
            const { data } = await api.delete(`/timeclass/domain/${dominio}`, { headers: { username } })
            listarDominios()
            toast.success(data.message)
        } catch (error) {
            if (error.response) {
                toast.error(error.response.data.error)
            } else {
                toast.error(error.message)
            }
        }
    }

    useEffect(() => {
        listarDominios()
    }, [])

    return (
        <>
            <Container>
                <Item key={`item-0`}>
                    <input
                        className="form-control me-2"
                        type="search"
                        placeholder="Busca"
                        aria-label="Busca"
                        value={busca}
                        onChange={(e) => setBusca(e.target.value)}
                    />
                </Item>
                {dominios
                    .filter((element) => element.VCH_DOMAIN.includes(busca))
                    .map((element) => {
                        const encontrado = dominiosTC.find((item) => item.domain === element.VCH_DOMAIN)

                        if (encontrado) {
                            return (
                                <Item key={element.INT_DOMAIN_KEY}>
                                    <Link to={`/timeclass/${encontrado.id}`}>
                                        <span className="nome">
                                            <FaRegBuilding /> {element.VCH_DOMAIN}
                                        </span>
                                    </Link>
                                    <div className="actions">
                                        <button
                                            type="button"
                                            className="btn btn-danger"
                                            onClick={() => removerDominio(encontrado.domain)}
                                        >
                                            <FaMinus /> Remover
                                        </button>
                                    </div>
                                </Item>
                            )
                        } else {
                            return (
                                <Item key={element.INT_DOMAIN_KEY}>
                                    <span className="nome">
                                        <FaBuilding /> {element.VCH_DOMAIN}
                                    </span>
                                    <div className="actions">
                                        <button
                                            type="button"
                                            className="btn btn-success"
                                            onClick={() => adicionarDominio(element.VCH_DOMAIN)}
                                        >
                                            <FaPlus /> Adicionar
                                        </button>
                                    </div>
                                </Item>
                            )
                        }
                    })}
            </Container>
        </>
    )
}

export default Dominios
