import React, { useEffect, useState, useContext } from "react"
import { Link } from "react-router-dom"
import { FaPlus, FaArrowLeft } from "react-icons/fa"
import { toast } from "react-toastify"

import api from "../../../services/api"
import { AuthContext } from "../../../context/AuthContext"
import { ButtonContainer } from "./styles"

const Usuarios = ({ match }) => {
    const { username } = useContext(AuthContext)
    const { id_domain, id_group } = match.params
    const [domain, setDomain] = useState({})
    const [group, setGroup] = useState([])
    const [users, setUsers] = useState([])
    const [usersInGroup, setUsersInGroup] = useState([])

    const [addUser, setAddUser] = useState({})

    const buscarDadosDominio = async () => {
        try {
            const { data } = await api.get(`/timeclass/domain/findById/${id_domain}`)
            setDomain(data)
        } catch (error) {
            toast.error(`Falha ao buscar dados do domínio`)
        }
    }

    const buscarDadosGrupo = async () => {
        if (domain.domain === undefined) {
            return
        }

        try {
            const { data } = await api.get(`/timeclass/domain/${domain.domain}/group/findById/${id_group}`, {
                headers: { username }
            })
            setGroup(data)
        } catch (error) {
            toast.error(`Falha ao buscar grupos do domínio`)
        }
    }

    const buscarDadosUsuarios = async () => {
        const { data: userList } = await api.get(`/timeclass/domain/${domain.domain}/user`, {
            headers: { username }
        })
        setUsers(userList)

        const { data: userInGroup } = await api.get(`/timeclass/domain/${domain.domain}/group/${group.name}/user`, {
            headers: { username }
        })
        setUsersInGroup(userInGroup)
    }

    const remover = async (id) => {
        try {
            await api.delete(`/timeclass/domain/${domain.domain}/group/${group.name}/user/${id}`, {
                headers: {
                    username
                }
            })

            toast.success(`Usuário ${id} removido com sucesso`)
        } catch (error) {
            toast.error(error.response.data.error)
        }

        buscarDadosUsuarios()
    }

    const adicionarUsuario = async () => {
        try {
            await api.post(
                `/timeclass/domain/${domain.domain}/group/${group.name}/user`,
                {
                    user: addUser
                },
                {
                    headers: {
                        username
                    }
                }
            )

            toast.success(`Usuário ${addUser} adicionado com sucesso`)

            // $("#modal-add").modal("hide")
        } catch (error) {
            toast.error(error.response.data.error)
        }

        buscarDadosUsuarios()
    }

    useEffect(() => {
        buscarDadosGrupo()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [domain])

    useEffect(() => {
        if (group.name !== undefined) {
            buscarDadosUsuarios()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [group])

    useEffect(() => {
        buscarDadosDominio()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <>
            <div className="container" style={{ paddingTop: "20px" }}>
                <div style={{ paddingBottom: "10px" }}>
                    <Link to={`/timeclass/${id_domain}`} type="button" className="btn btn-warning">
                        <FaArrowLeft /> Voltar
                    </Link>{" "}
                    <button
                        type="button"
                        data-bs-toggle="modal"
                        data-bs-target="#modal-add"
                        className="btn btn-primary"
                    >
                        <FaPlus /> Adicionar
                    </button>
                </div>

                <div className="alert alert-light" role="alert">
                    Usuários no grupo: <strong>{group.name}</strong>
                </div>

                <table className="table">
                    <thead>
                        <tr>
                            <th scope="col">#</th>
                            <th scope="col">Nome</th>
                            <th scope="col">Adicionado Em</th>
                            <th scope="col">Ações</th>
                        </tr>
                    </thead>
                    <tbody>
                        {usersInGroup.map((user) => {
                            return (
                                <tr key={user.id}>
                                    <td>{user.id}</td>
                                    <td>{user.name}</td>
                                    <td>{user.original_service_class_name}</td>
                                    <td>
                                        <ButtonContainer>
                                            <div>
                                                <button
                                                    type="button"
                                                    className="btn btn-danger btn-sm"
                                                    onClick={() => {
                                                        remover(user.name)
                                                    }}
                                                >
                                                    Remover
                                                </button>
                                                {/* <Link
                                                    to={`/timeclass/${id_domain}/${group.id}`}
                                                    type="button"
                                                    className="btn btn-warning btn-sm"
                                                >
                                                    Usuários
                                                </Link>
                                                <Link
                                                    to={`/timeclass/${id_domain}/${group.id}`}
                                                    type="button"
                                                    className="btn btn-warning btn-sm"
                                                >
                                                    Horários
                                                </Link> */}
                                            </div>
                                        </ButtonContainer>
                                    </td>
                                </tr>
                            )
                        })}
                    </tbody>
                </table>

                <div className="modal" id="modal-add" tabIndex="-1">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">Adicionar usuário</h5>
                                <button
                                    type="button"
                                    className="btn-close"
                                    data-bs-dismiss="modal"
                                    aria-label="Close"
                                ></button>
                            </div>
                            <div className="modal-body">
                                <div className="col-12">
                                    <label htmlFor="did" className="form-label">
                                        Usuário
                                    </label>
                                    <select
                                        id="did"
                                        className="form-select"
                                        onChange={(e) => {
                                            setAddUser(e.target.value)
                                        }}
                                        value={addUser}
                                    >
                                        <option></option>
                                        {users
                                            .filter((item) => !usersInGroup.map((i) => i.name).includes(item.user))
                                            .map((item) => {
                                                return (
                                                    <option key={item.user} value={item.user}>
                                                        {item.user}
                                                    </option>
                                                )
                                            })}
                                    </select>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">
                                    Fechar
                                </button>
                                <button
                                    type="button"
                                    className="btn btn-primary"
                                    onClick={() => {
                                        adicionarUsuario()
                                    }}
                                >
                                    Adicionar
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Usuarios
