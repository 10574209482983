import { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { FaEdit, FaWindowClose, FaPlus } from 'react-icons/fa'

import { Container, ButtonContainer, ButtonContainerTable, ContainerInputHorizontal } from './styles';
import api from '../../../services/apiMiddleware'

const BotChat = () => {
  const [botChat, setBotChat] = useState([])

  const [botIdEdit, setBotIdEdit] = useState('')
  const [botId, setBotId] = useState('')
  const [botToken, setBotToken] = useState('')
  const [typebotId, setTypebotId] = useState('')

  const getList = async () => {
    try {
      api.get('/chat/typebot').then(response => {
        setBotChat(response.data)
      })
    } catch (error) {
      toast.error(error.message)
    }
  }

  useEffect(() => {
    getList()
  }, [])

  const handleAddBotChat = async () => {
    try {
      const response = await api.post('/chat/typebot', {
        bot_id: botId,
        bot_token: botToken,
        typebot_id: typebotId
      })

      document.getElementById('btnCloseModal').click();
      toast.success(response.data.message)

      setBotId('')
      setBotToken('')
      setTypebotId('')

      getList()
    } catch (error) {
      toast.error(error.message)
    }
  }

  const fillFields = async ({ id }) => {
    const config = botChat.find(bot => bot.id === id)

    setBotIdEdit(config.id)
    setBotId(config.bot_id)
    setBotToken(config.bot_token)
    setTypebotId(config.typebot_id)
  }

  const fillFieldsEdit = async ({ id }) => {
    try {
      const response = await api.get(`/chat/typebot/${id}`)
      setBotToken(response.data.bot_token)

      const config = botChat.find(bot => bot.id === id)

      setBotIdEdit(config.id)
      setBotId(config.bot_id)
      setTypebotId(config.typebot_id)
    } catch (error) {
      toast.error(error.message)
    }
  }

  const handleEditBotChat = async () => {
    try {
      const response = await api.put(`/chat/typebot/${botIdEdit}`, {
        bot_id: botId,
        bot_token: botToken,
        typebot_id: typebotId
      })

      document.getElementById('btnCloseModalAlterar').click();
      toast.success(response.data.message)

      setBotId('')
      setBotToken('')
      setTypebotId('')

      getList()
    } catch (error) {
      toast.error(error.message)
    }
  }

  const handleRemoveBotChat = async () => {
    try {
      await api.delete(`/chat/typebot/${botIdEdit}`)
      document.getElementById('btnCloseModalRemover').click();
      toast.success('Removido com sucesso')

      setBotId('')
      setBotToken('')
      setTypebotId('')

      getList()
    } catch (error) {
      toast.error(error.message)
    }
  }

  return (
    <Container>
      <div>
        <ButtonContainer>
          <button type="button" className="btn btn-primary" data-bs-toggle="modal" data-bs-target="#modal_adicionar" onClick={() => {
            setBotId('')
            setBotToken('')
            setTypebotId('')
          }}><FaPlus /> Adicionar</button>
        </ButtonContainer>
        <table className="table table-striped table-bordered">
          <thead>
            <tr>
              <th scope="col" className='text-center'>ID</th>
              <th scope="col" className='text-center'>Bot</th>
              <th scope="col" className='text-center'>Chat</th>
              <th scope="col" className='text-center'>Ações</th>
            </tr>
          </thead>
          <tbody>
            {botChat && botChat.length > 0 && botChat.map(bot => {
              return (
                <tr key={bot.id}>
                  <th scope="row" className='text-center'>{bot.id}</th>
                  <td className='text-center'>{bot.bot_id}</td>
                  <td className='text-center'>{bot.typebot_id}</td>
                  <td>
                    <ButtonContainerTable>
                      <button type="button" className="btn btn-warning btn-sm" data-bs-toggle="modal" data-bs-target="#modal_alterar" onClick={() => {
                        fillFieldsEdit({ id: bot.id })
                      }}><FaEdit /> Editar</button>
                      <button type="button" className="btn btn-danger btn-sm" data-bs-toggle="modal" data-bs-target="#modal_remover" onClick={() => {
                        fillFields({ id: bot.id })
                      }}><FaWindowClose /> Remover</button>
                    </ButtonContainerTable>
                  </td>
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>

      {/* Modal Adicionar */}
      <div className="modal fade" id="modal_adicionar" tabIndex="-1" aria-labelledby="modal_adicionar_label" aria-hidden="true">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="modal_adicionar_label">Adicionar Vinculo Chat Typebot</h1>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body">
              <div className="mb-3">
                <ContainerInputHorizontal>
                  <div className="mb-3">
                    <label htmlFor="exampleFormControlInput1" className="form-label">Bot ID</label>
                    <input type="email" value={botId} onChange={e => setBotId(e.target.value)} className="form-control" id="exampleFormControlInput1" placeholder="Bot ID" />
                  </div>

                  <div className="mb-3">
                    <label htmlFor="exampleFormControlInput1" className="form-label">Bot Token</label>
                    <input type="email" value={botToken} onChange={e => setBotToken(e.target.value)} className="form-control" id="exampleFormControlInput1" placeholder="Bot Token" />
                  </div>
                </ContainerInputHorizontal>


                <div className="mb-3">
                  <label htmlFor="exampleFormControlInput1" className="form-label">Typebot ID</label>
                  <input type="email" value={typebotId} onChange={e => setTypebotId(e.target.value)} className="form-control" id="exampleFormControlInput1" placeholder="Typebot ID" />
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button id='btnCloseModal' type="button" className="btn btn-secondary" data-bs-dismiss="modal">Fechar</button>
              <button type="button" className="btn btn-primary" onClick={() => { handleAddBotChat() }}>Adicionar</button>
            </div>
          </div>
        </div>
      </div>

      {/* Modal Alterar */}
      <div className="modal fade" id="modal_alterar" tabIndex="-1" aria-labelledby="modal_alterar_label" aria-hidden="true">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="modal_alterar_label">Alterar Vinculo Chat Typebot</h1>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body">
              <div className="mb-3">
                <ContainerInputHorizontal>
                  <div className="mb-3">
                    <label htmlFor="exampleFormControlInput1" className="form-label">Bot ID</label>
                    <input type="email" value={botId} onChange={e => setBotId(e.target.value)} className="form-control" id="exampleFormControlInput1" placeholder="Bot ID" />
                  </div>

                  <div className="mb-3">
                    <label htmlFor="exampleFormControlInput1" className="form-label">Bot Token</label>
                    <input type="email" value={botToken} onChange={e => setBotToken(e.target.value)} className="form-control" id="exampleFormControlInput1" placeholder="Bot Token" />
                  </div>
                </ContainerInputHorizontal>


                <div className="mb-3">
                  <label htmlFor="exampleFormControlInput1" className="form-label">Typebot ID</label>
                  <input type="email" value={typebotId} onChange={e => setTypebotId(e.target.value)} className="form-control" id="exampleFormControlInput1" placeholder="Typebot ID" />
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button id='btnCloseModalAlterar' type="button" className="btn btn-secondary" data-bs-dismiss="modal">Fechar</button>
              <button type="button" className="btn btn-primary" onClick={(() => { handleEditBotChat() })}>Alterar</button>
            </div>
          </div>
        </div>
      </div>

      {/* Modal Remover */}
      <div className="modal fade" id="modal_remover" tabIndex="-1" aria-labelledby="modal_remover_label" aria-hidden="true">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="modal_remover_label">Remover Vinculo Chat Typebot</h1>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body">
              <div className="mb-3">
                <div className="mb-3">
                  <label htmlFor="exampleFormControlInput1" className="form-label">Bot ID</label>
                  <input type="email" disabled value={botId} onChange={e => setBotId(e.target.value)} className="form-control" id="exampleFormControlInput1" placeholder="Bot ID" />
                </div>

                <div className="mb-3">
                  <label htmlFor="exampleFormControlInput1" className="form-label">Typebot ID</label>
                  <input type="email" disabled value={typebotId} onChange={e => setTypebotId(e.target.value)} className="form-control" id="exampleFormControlInput1" placeholder="Typebot ID" />
                </div>

              </div>
            </div>
            <div className="modal-footer">
              <button id='btnCloseModalRemover' type="button" className="btn btn-secondary" data-bs-dismiss="modal">Fechar</button>
              <button type="button" className="btn btn-danger" onClick={(() => { handleRemoveBotChat() })}>Remover</button>
            </div>
          </div>
        </div>
      </div>
    </Container>
  )
}

export default BotChat;
