import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { toast } from 'react-toastify'
import { FaPlus, FaArrowLeft, FaDoorClosed } from 'react-icons/fa'

import api from '../../services/api'
import { Segunda, Terca, Quarta, Quinta, Sexta, Sabado, Domingo, Separador, ButtonContainer } from './styles'

const Programacoes = ({ match }) => {
    const { id_domain } = match.params
    const semana = ["Domingo", "Segunda", "Terça", "Quarta", "Quinta", "Sexta", "Sabado"]

    const [programacoes, setProgramacoes] = useState([])

    const buscarProgramacoes = async () => {
        try {
            const { data } = await api.get(`/promptmode/domain/${id_domain}`)
            setProgramacoes(data)
        } catch (error) {
            
        }
    }

    const remover = async (id) => {
        try {
            await api.delete(`/promptmode/domain/${id_domain}/${id}`)
            toast.success(`Programação removida`)
            buscarProgramacoes()
        } catch (error) {
            toast.error(`Falha ao deletar programação`)
        }
    }

    useEffect(() => {
        buscarProgramacoes()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <div style={{padding: "20px"}}>
            <div className="row justify-content-md-center">
                <div className="col col-lg-8">
                    <Link to={`/promptmode`} type="button" className="btn btn-warning"><FaArrowLeft /> Voltar</Link>
                    {' '}
                    <Link to={`/promptmode/${id_domain}/adicionar`} type="button" className="btn btn-primary"><FaPlus /> Adicionar</Link>
                    {' '}
                    <Link to={`/promptmode/feriados/${id_domain}`} type="button" className="btn btn-success"><FaDoorClosed /> Feriados</Link>
                    <table className="table">
                        <thead>
                            <tr>
                                <th scope="col">#</th>
                                <th scope="col">DID</th>
                                <th scope="col">Destino</th>
                                <th scope="col">Dia da semana</th>
                                <th scope="col">Hora</th>
                                <th scope="col">Minuto</th>
                                <th scope="col">Ações</th>
                            </tr>
                        </thead>
                        <tbody>
                            {programacoes.map((item, index, arr) => {
                                if(semana[item.dia_semana] === 'Segunda'){
                                    if(index !== 0){
                                        if(item.did !== arr[index-1].did){
                                            return (
                                                <>
                                                <Separador key={item.id+'s'}>
                                                    <td>&nbsp;</td>
                                                    <td>&nbsp;</td>
                                                    <td>&nbsp;</td>
                                                    <td>&nbsp;</td>
                                                    <td>&nbsp;</td>
                                                    <td>&nbsp;</td>
                                                    <td>&nbsp;</td>
                                                </Separador>
                                                <Segunda key={item.id}>
                                                    <th scope="row">{item.id}</th>
                                                    <td>{item.did}</td>
                                                    <td>{item.destino}</td>
                                                    <td>{semana[item.dia_semana]}</td>
                                                    <td>{item.hora.toString().padStart(2, "0")}</td>
                                                    <td>{item.minuto.toString().padStart(2, "0")}</td>
                                                    <ButtonContainer>
                                                        <div>
                                                            <button type="button" className="btn btn-danger btn-sm" onClick={() => { remover(item.id) }}>Remover</button>
                                                            <Link to={`/promptmode/${id_domain}/${item.id}`} type="button" className="btn btn-warning btn-sm">Editar</Link>
                                                        </div>
                                                    </ButtonContainer>
                                                </Segunda>
                                                </>
                                            )
                                        }
                                        if(item.dia_semana !== arr[index-1].dia_semana){
                                            return (
                                                <>
                                                <td key={item.id+'s'}>&nbsp;</td>
                                                <Segunda key={item.id}>
                                                    <th scope="row">{item.id}</th>
                                                    <td>{item.did}</td>
                                                    <td>{item.destino}</td>
                                                    <td>{semana[item.dia_semana]}</td>
                                                    <td>{item.hora.toString().padStart(2, "0")}</td>
                                                    <td>{item.minuto.toString().padStart(2, "0")}</td>
                                                    <ButtonContainer>
                                                        <div>
                                                            <button type="button" className="btn btn-danger btn-sm" onClick={() => { remover(item.id) }}>Remover</button>
                                                            <Link to={`/promptmode/${id_domain}/${item.id}`} type="button" className="btn btn-warning btn-sm">Editar</Link>
                                                        </div>
                                                    </ButtonContainer>
                                                </Segunda>
                                                </>
                                            )
                                        }
                                    }

                                    return (
                                        <Segunda key={item.id}>
                                            <th scope="row">{item.id}</th>
                                            <td>{item.did}</td>
                                            <td>{item.destino}</td>
                                            <td>{semana[item.dia_semana]}</td>
                                            <td>{item.hora.toString().padStart(2, "0")}</td>
                                            <td>{item.minuto.toString().padStart(2, "0")}</td>
                                            <ButtonContainer>
                                                <div>
                                                    <button type="button" className="btn btn-danger btn-sm" onClick={() => { remover(item.id) }}>Remover</button>
                                                    <Link to={`/promptmode/${id_domain}/${item.id}`} type="button" className="btn btn-warning btn-sm">Editar</Link>
                                                </div>
                                            </ButtonContainer>
                                        </Segunda>
                                    )
                                }
                                if(semana[item.dia_semana] === 'Terça'){
                                    if(index !== 0){
                                        if(item.did !== arr[index-1].did){
                                            return (
                                                <>
                                                <Separador key={item.id+'s'}>
                                                    <td>&nbsp;</td>
                                                    <td>&nbsp;</td>
                                                    <td>&nbsp;</td>
                                                    <td>&nbsp;</td>
                                                    <td>&nbsp;</td>
                                                    <td>&nbsp;</td>
                                                    <td>&nbsp;</td>
                                                </Separador>
                                                <Terca key={item.id}>
                                                    <th scope="row">{item.id}</th>
                                                    <td>{item.did}</td>
                                                    <td>{item.destino}</td>
                                                    <td>{semana[item.dia_semana]}</td>
                                                    <td>{item.hora.toString().padStart(2, "0")}</td>
                                                    <td>{item.minuto.toString().padStart(2, "0")}</td>
                                                    <ButtonContainer>
                                                        <div>
                                                            <button type="button" className="btn btn-danger btn-sm" onClick={() => { remover(item.id) }}>Remover</button>
                                                            <Link to={`/promptmode/${id_domain}/${item.id}`} type="button" className="btn btn-warning btn-sm">Editar</Link>
                                                        </div>
                                                    </ButtonContainer>
                                                </Terca>
                                                </>
                                            )
                                        }
                                        if(item.dia_semana !== arr[index-1].dia_semana){
                                            return (
                                                <>
                                                <td key={item.id+'s'}>&nbsp;</td>
                                                <Terca key={item.id}>
                                                    <th scope="row">{item.id}</th>
                                                    <td>{item.did}</td>
                                                    <td>{item.destino}</td>
                                                    <td>{semana[item.dia_semana]}</td>
                                                    <td>{item.hora.toString().padStart(2, "0")}</td>
                                                    <td>{item.minuto.toString().padStart(2, "0")}</td>
                                                    <ButtonContainer>
                                                        <div>
                                                            <button type="button" className="btn btn-danger btn-sm" onClick={() => { remover(item.id) }}>Remover</button>
                                                            <Link to={`/promptmode/${id_domain}/${item.id}`} type="button" className="btn btn-warning btn-sm">Editar</Link>
                                                        </div>
                                                    </ButtonContainer>
                                                </Terca>
                                                </>
                                            )
                                        }
                                    }

                                    return (
                                        <Terca key={item.id}>
                                            <th scope="row">{item.id}</th>
                                            <td>{item.did}</td>
                                            <td>{item.destino}</td>
                                            <td>{semana[item.dia_semana]}</td>
                                            <td>{item.hora.toString().padStart(2, "0")}</td>
                                            <td>{item.minuto.toString().padStart(2, "0")}</td>
                                            <ButtonContainer>
                                                <div>
                                                    <button type="button" className="btn btn-danger btn-sm" onClick={() => { remover(item.id) }}>Remover</button>
                                                    <Link to={`/promptmode/${id_domain}/${item.id}`} type="button" className="btn btn-warning btn-sm">Editar</Link>
                                                </div>
                                            </ButtonContainer>
                                        </Terca>
                                    )
                                }
                                if(semana[item.dia_semana] === 'Quarta'){
                                    if(index !== 0){
                                        if(item.did !== arr[index-1].did){
                                            return (
                                                <>
                                                <Separador key={item.id+'s'}>
                                                    <td>&nbsp;</td>
                                                    <td>&nbsp;</td>
                                                    <td>&nbsp;</td>
                                                    <td>&nbsp;</td>
                                                    <td>&nbsp;</td>
                                                    <td>&nbsp;</td>
                                                    <td>&nbsp;</td>
                                                </Separador>
                                                <Quarta key={item.id}>
                                                    <th scope="row">{item.id}</th>
                                                    <td>{item.did}</td>
                                                    <td>{item.destino}</td>
                                                    <td>{semana[item.dia_semana]}</td>
                                                    <td>{item.hora.toString().padStart(2, "0")}</td>
                                                    <td>{item.minuto.toString().padStart(2, "0")}</td>
                                                    <ButtonContainer>
                                                        <div>
                                                            <button type="button" className="btn btn-danger btn-sm" onClick={() => { remover(item.id) }}>Remover</button>
                                                            <Link to={`/promptmode/${id_domain}/${item.id}`} type="button" className="btn btn-warning btn-sm">Editar</Link>
                                                        </div>
                                                    </ButtonContainer>
                                                </Quarta>
                                                </>
                                            )
                                        }
                                        if(item.dia_semana !== arr[index-1].dia_semana){
                                            return (
                                                <>
                                                <td key={item.id+'s'}>&nbsp;</td>
                                                <Quarta key={item.id}>
                                                    <th scope="row">{item.id}</th>
                                                    <td>{item.did}</td>
                                                    <td>{item.destino}</td>
                                                    <td>{semana[item.dia_semana]}</td>
                                                    <td>{item.hora.toString().padStart(2, "0")}</td>
                                                    <td>{item.minuto.toString().padStart(2, "0")}</td>
                                                    <ButtonContainer>
                                                        <div>
                                                            <button type="button" className="btn btn-danger btn-sm" onClick={() => { remover(item.id) }}>Remover</button>
                                                            <Link to={`/promptmode/${id_domain}/${item.id}`} type="button" className="btn btn-warning btn-sm">Editar</Link>
                                                        </div>
                                                    </ButtonContainer>
                                                </Quarta>
                                                </>
                                            )
                                        }
                                    }

                                    return (
                                        <Quarta key={item.id}>
                                            <th scope="row">{item.id}</th>
                                            <td>{item.did}</td>
                                            <td>{item.destino}</td>
                                            <td>{semana[item.dia_semana]}</td>
                                            <td>{item.hora.toString().padStart(2, "0")}</td>
                                            <td>{item.minuto.toString().padStart(2, "0")}</td>
                                            <ButtonContainer>
                                                <div>
                                                    <button type="button" className="btn btn-danger btn-sm" onClick={() => { remover(item.id) }}>Remover</button>
                                                    <Link to={`/promptmode/${id_domain}/${item.id}`} type="button" className="btn btn-warning btn-sm">Editar</Link>
                                                </div>
                                            </ButtonContainer>
                                        </Quarta>
                                    )
                                }
                                if(semana[item.dia_semana] === 'Quinta'){
                                    if(index !== 0){
                                        if(item.did !== arr[index-1].did){
                                            return (
                                                <>
                                                <Separador key={item.id+'s'}>
                                                    <td>&nbsp;</td>
                                                    <td>&nbsp;</td>
                                                    <td>&nbsp;</td>
                                                    <td>&nbsp;</td>
                                                    <td>&nbsp;</td>
                                                    <td>&nbsp;</td>
                                                    <td>&nbsp;</td>
                                                </Separador>
                                                <Quinta key={item.id}>
                                                    <th scope="row">{item.id}</th>
                                                    <td>{item.did}</td>
                                                    <td>{item.destino}</td>
                                                    <td>{semana[item.dia_semana]}</td>
                                                    <td>{item.hora.toString().padStart(2, "0")}</td>
                                                    <td>{item.minuto.toString().padStart(2, "0")}</td>
                                                    <ButtonContainer>
                                                        <div>
                                                            <button type="button" className="btn btn-danger btn-sm" onClick={() => { remover(item.id) }}>Remover</button>
                                                            <Link to={`/promptmode/${id_domain}/${item.id}`} type="button" className="btn btn-warning btn-sm">Editar</Link>
                                                        </div>
                                                    </ButtonContainer>
                                                </Quinta>
                                                </>
                                            )
                                        }
                                        if(item.dia_semana !== arr[index-1].dia_semana){
                                            return (
                                                <>
                                                <td key={item.id+'s'}>&nbsp;</td>
                                                <Quinta key={item.id}>
                                                    <th scope="row">{item.id}</th>
                                                    <td>{item.did}</td>
                                                    <td>{item.destino}</td>
                                                    <td>{semana[item.dia_semana]}</td>
                                                    <td>{item.hora.toString().padStart(2, "0")}</td>
                                                    <td>{item.minuto.toString().padStart(2, "0")}</td>
                                                    <ButtonContainer>
                                                        <div>
                                                            <button type="button" className="btn btn-danger btn-sm" onClick={() => { remover(item.id) }}>Remover</button>
                                                            <Link to={`/promptmode/${id_domain}/${item.id}`} type="button" className="btn btn-warning btn-sm">Editar</Link>
                                                        </div>
                                                    </ButtonContainer>
                                                </Quinta>
                                                </>
                                            )
                                        }
                                    }

                                    return (
                                        <Quinta key={item.id}>
                                            <th scope="row">{item.id}</th>
                                            <td>{item.did}</td>
                                            <td>{item.destino}</td>
                                            <td>{semana[item.dia_semana]}</td>
                                            <td>{item.hora.toString().padStart(2, "0")}</td>
                                            <td>{item.minuto.toString().padStart(2, "0")}</td>
                                            <ButtonContainer>
                                                <div>
                                                    <button type="button" className="btn btn-danger btn-sm" onClick={() => { remover(item.id) }}>Remover</button>
                                                    <Link to={`/promptmode/${id_domain}/${item.id}`} type="button" className="btn btn-warning btn-sm">Editar</Link>
                                                </div>
                                            </ButtonContainer>
                                        </Quinta>
                                    )
                                }
                                if(semana[item.dia_semana] === 'Sexta'){
                                    if(index !== 0){
                                        if(item.did !== arr[index-1].did){
                                            return (
                                                <>
                                                <Separador key={item.id+'s'}>
                                                    <td>&nbsp;</td>
                                                    <td>&nbsp;</td>
                                                    <td>&nbsp;</td>
                                                    <td>&nbsp;</td>
                                                    <td>&nbsp;</td>
                                                    <td>&nbsp;</td>
                                                    <td>&nbsp;</td>
                                                </Separador>
                                                <Sexta key={item.id}>
                                                    <th scope="row">{item.id}</th>
                                                    <td>{item.did}</td>
                                                    <td>{item.destino}</td>
                                                    <td>{semana[item.dia_semana]}</td>
                                                    <td>{item.hora.toString().padStart(2, "0")}</td>
                                                    <td>{item.minuto.toString().padStart(2, "0")}</td>
                                                    <ButtonContainer>
                                                        <div>
                                                            <button type="button" className="btn btn-danger btn-sm" onClick={() => { remover(item.id) }}>Remover</button>
                                                            <Link to={`/promptmode/${id_domain}/${item.id}`} type="button" className="btn btn-warning btn-sm">Editar</Link>
                                                        </div>
                                                    </ButtonContainer>
                                                </Sexta>
                                                </>
                                            )
                                        }
                                        if(item.dia_semana !== arr[index-1].dia_semana){
                                            return (
                                                <>
                                                <td key={item.id+'s'}>&nbsp;</td>
                                                <Sexta key={item.id}>
                                                    <th scope="row">{item.id}</th>
                                                    <td>{item.did}</td>
                                                    <td>{item.destino}</td>
                                                    <td>{semana[item.dia_semana]}</td>
                                                    <td>{item.hora.toString().padStart(2, "0")}</td>
                                                    <td>{item.minuto.toString().padStart(2, "0")}</td>
                                                    <ButtonContainer>
                                                        <div>
                                                            <button type="button" className="btn btn-danger btn-sm" onClick={() => { remover(item.id) }}>Remover</button>
                                                            <Link to={`/promptmode/${id_domain}/${item.id}`} type="button" className="btn btn-warning btn-sm">Editar</Link>
                                                        </div>
                                                    </ButtonContainer>
                                                </Sexta>
                                                </>
                                            )
                                        }
                                    }

                                    return (
                                        <Sexta key={item.id}>
                                            <th scope="row">{item.id}</th>
                                            <td>{item.did}</td>
                                            <td>{item.destino}</td>
                                            <td>{semana[item.dia_semana]}</td>
                                            <td>{item.hora.toString().padStart(2, "0")}</td>
                                            <td>{item.minuto.toString().padStart(2, "0")}</td>
                                            <ButtonContainer>
                                                <div>
                                                    <button type="button" className="btn btn-danger btn-sm" onClick={() => { remover(item.id) }}>Remover</button>
                                                    <Link to={`/promptmode/${id_domain}/${item.id}`} type="button" className="btn btn-warning btn-sm">Editar</Link>
                                                </div>
                                            </ButtonContainer>
                                        </Sexta>
                                    )
                                }
                                if(semana[item.dia_semana] === 'Sabado'){
                                    if(index !== 0){
                                        if(item.did !== arr[index-1].did){
                                            return (
                                                <>
                                                <Separador key={item.id+'s'}>
                                                    <td>&nbsp;</td>
                                                    <td>&nbsp;</td>
                                                    <td>&nbsp;</td>
                                                    <td>&nbsp;</td>
                                                    <td>&nbsp;</td>
                                                    <td>&nbsp;</td>
                                                    <td>&nbsp;</td>
                                                </Separador>
                                                <Sabado key={item.id}>
                                                    <th scope="row">{item.id}</th>
                                                    <td>{item.did}</td>
                                                    <td>{item.destino}</td>
                                                    <td>{semana[item.dia_semana]}</td>
                                                    <td>{item.hora.toString().padStart(2, "0")}</td>
                                                    <td>{item.minuto.toString().padStart(2, "0")}</td>
                                                    <ButtonContainer>
                                                        <div>
                                                            <button type="button" className="btn btn-danger btn-sm" onClick={() => { remover(item.id) }}>Remover</button>
                                                            <Link to={`/promptmode/${id_domain}/${item.id}`} type="button" className="btn btn-warning btn-sm">Editar</Link>
                                                        </div>
                                                    </ButtonContainer>
                                                </Sabado>
                                                </>
                                            )
                                        }
                                        if(item.dia_semana !== arr[index-1].dia_semana){
                                            return (
                                                <>
                                                <td key={item.id+'s'}>&nbsp;</td>
                                                <Sabado key={item.id}>
                                                    <th scope="row">{item.id}</th>
                                                    <td>{item.did}</td>
                                                    <td>{item.destino}</td>
                                                    <td>{semana[item.dia_semana]}</td>
                                                    <td>{item.hora.toString().padStart(2, "0")}</td>
                                                    <td>{item.minuto.toString().padStart(2, "0")}</td>
                                                    <ButtonContainer>
                                                        <div>
                                                            <button type="button" className="btn btn-danger btn-sm" onClick={() => { remover(item.id) }}>Remover</button>
                                                            <Link to={`/promptmode/${id_domain}/${item.id}`} type="button" className="btn btn-warning btn-sm">Editar</Link>
                                                        </div>
                                                    </ButtonContainer>
                                                </Sabado>
                                                </>
                                            )
                                        }
                                    }

                                    return (
                                        <Sabado key={item.id}>
                                            <th scope="row">{item.id}</th>
                                            <td>{item.did}</td>
                                            <td>{item.destino}</td>
                                            <td>{semana[item.dia_semana]}</td>
                                            <td>{item.hora.toString().padStart(2, "0")}</td>
                                            <td>{item.minuto.toString().padStart(2, "0")}</td>
                                            <ButtonContainer>
                                                <div>
                                                    <button type="button" className="btn btn-danger btn-sm" onClick={() => { remover(item.id) }}>Remover</button>
                                                    <Link to={`/promptmode/${id_domain}/${item.id}`} type="button" className="btn btn-warning btn-sm">Editar</Link>
                                                </div>
                                            </ButtonContainer>
                                        </Sabado>
                                    )
                                }
                                if(semana[item.dia_semana] === 'Domingo'){
                                    if(index !== 0){
                                        if(item.did !== arr[index-1].did){
                                            return (
                                                <>
                                                <Separador key={item.id+'s'}>
                                                    <td>&nbsp;</td>
                                                    <td>&nbsp;</td>
                                                    <td>&nbsp;</td>
                                                    <td>&nbsp;</td>
                                                    <td>&nbsp;</td>
                                                    <td>&nbsp;</td>
                                                    <td>&nbsp;</td>
                                                </Separador>
                                                <Domingo key={item.id}>
                                                    <th scope="row">{item.id}</th>
                                                    <td>{item.did}</td>
                                                    <td>{item.destino}</td>
                                                    <td>{semana[item.dia_semana]}</td>
                                                    <td>{item.hora.toString().padStart(2, "0")}</td>
                                                    <td>{item.minuto.toString().padStart(2, "0")}</td>
                                                    <ButtonContainer>
                                                        <div>
                                                            <button type="button" className="btn btn-danger btn-sm" onClick={() => { remover(item.id) }}>Remover</button>
                                                            <Link to={`/promptmode/${id_domain}/${item.id}`} type="button" className="btn btn-warning btn-sm">Editar</Link>
                                                        </div>
                                                    </ButtonContainer>
                                                </Domingo>
                                                </>
                                            )
                                        }
                                        if(item.dia_semana !== arr[index-1].dia_semana){
                                            return (
                                                <>
                                                <td key={item.id+'s'}>&nbsp;</td>
                                                <Domingo key={item.id}>
                                                    <th scope="row">{item.id}</th>
                                                    <td>{item.did}</td>
                                                    <td>{item.destino}</td>
                                                    <td>{semana[item.dia_semana]}</td>
                                                    <td>{item.hora.toString().padStart(2, "0")}</td>
                                                    <td>{item.minuto.toString().padStart(2, "0")}</td>
                                                    <ButtonContainer>
                                                        <div>
                                                            <button type="button" className="btn btn-danger btn-sm" onClick={() => { remover(item.id) }}>Remover</button>
                                                            <Link to={`/promptmode/${id_domain}/${item.id}`} type="button" className="btn btn-warning btn-sm">Editar</Link>
                                                        </div>
                                                    </ButtonContainer>
                                                </Domingo>
                                                </>
                                            )
                                        }
                                    }

                                    return (
                                        <Domingo key={item.id}>
                                            <th scope="row">{item.id}</th>
                                            <td>{item.did}</td>
                                            <td>{item.destino}</td>
                                            <td>{semana[item.dia_semana]}</td>
                                            <td>{item.hora.toString().padStart(2, "0")}</td>
                                            <td>{item.minuto.toString().padStart(2, "0")}</td>
                                            <ButtonContainer>
                                                <div>
                                                    <button type="button" className="btn btn-danger btn-sm" onClick={() => { remover(item.id) }}>Remover</button>
                                                    <Link to={`/promptmode/${id_domain}/${item.id}`} type="button" className="btn btn-warning btn-sm">Editar</Link>
                                                </div>
                                            </ButtonContainer>
                                        </Domingo>
                                    )
                                }

                                return (
                                    <tr key={item.id}>
                                        <th scope="row">{item.id}</th>
                                        <td>{item.did}</td>
                                        <td>{item.destino}</td>
                                        <td>{semana[item.dia_semana]}</td>
                                        <td>{item.hora.toString().padStart(2, "0")}</td>
                                        <td>{item.minuto.toString().padStart(2, "0")}</td>
                                        <ButtonContainer>
                                            <div>
                                                <button type="button" className="btn btn-danger btn-sm" onClick={() => { remover(item.id) }}>Remover</button>
                                                <Link to={`/promptmode/${id_domain}/${item.id}`} type="button" className="btn btn-warning btn-sm">Editar</Link>
                                            </div>
                                        </ButtonContainer>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    )
}

export default Programacoes;