import React, { useEffect, useState, useContext } from "react"
import { Link } from "react-router-dom"
import { FaPlus, FaArrowLeft } from "react-icons/fa"
import { toast } from "react-toastify"
import { format } from "date-fns"

import api from "../../../services/api"
import { AuthContext } from "../../../context/AuthContext"
import { ButtonContainer } from "./styles"

const Grupos = ({ match }) => {
    const { username } = useContext(AuthContext)
    const { id_domain } = match.params
    const [domain, setDomain] = useState({})
    const [groups, setGroups] = useState([])

    const [grupo, setGrupo] = useState("")

    const buscarDadosDominio = async () => {
        try {
            const { data } = await api.get(`/timeclass/domain/findById/${id_domain}`)
            setDomain(data)
        } catch (error) {
            toast.error(`Falha ao buscar dados do domínio`)
        }
    }

    const buscarGruposDominio = async () => {
        if (domain.domain === undefined) {
            return
        }

        try {
            const { data } = await api.get(`/timeclass/domain/${domain.domain}/group`, { headers: { username } })
            setGroups(data)
        } catch (error) {
            toast.error(`Falha ao buscar grupos do domínio`)
        }
    }

    const remover = async (name) => {
        try {
            await api.delete(`/timeclass/domain/${domain.domain}/group/${name}`, { headers: { username } })
            toast.success(`Grupo: ${name} removido com sucesso`)
            buscarGruposDominio()
        } catch (error) {
            toast.error(`Falha ao remover grupo, os usuarios e regras devem ser deletadas`)
        }
    }

    const adicionarGrupo = async () => {
        if (grupo.length >= 3) {
            try {
                await api.post(
                    `/timeclass/domain/${domain.domain}/group`,
                    {
                        name: grupo
                    },
                    {
                        headers: {
                            username
                        }
                    }
                )

                toast.success(`Grupo: ${grupo} adicionado com sucesso`)
                buscarGruposDominio()
            } catch (error) {
                toast.error(error.response.data.error)
            }
        } else {
            toast.error("Nome do grupo deve ter no minino 3 caracteres")
        }
    }

    useEffect(() => {
        buscarGruposDominio()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [domain])

    useEffect(() => {
        buscarDadosDominio()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <>
            <div className="container" style={{ paddingTop: "20px" }}>
                <div style={{ paddingBottom: "10px" }}>
                    <Link to={`/timeclass`} type="button" className="btn btn-warning">
                        <FaArrowLeft /> Voltar
                    </Link>{" "}
                    <button
                        type="button"
                        data-bs-toggle="modal"
                        data-bs-target="#modal-add"
                        className="btn btn-primary"
                    >
                        <FaPlus /> Adicionar
                    </button>
                </div>

                <div className="alert alert-light" role="alert">
                    Grupos de usuários
                </div>

                <table className="table">
                    <thead>
                        <tr>
                            <th scope="col">#</th>
                            <th scope="col">Nome</th>
                            <th scope="col">Adicionado Em</th>
                            <th scope="col">Ações</th>
                        </tr>
                    </thead>
                    <tbody>
                        {groups.map((group) => {
                            return (
                                <tr key={group.id}>
                                    <td>{group.id}</td>
                                    <td>{group.name}</td>
                                    <td>{format(new Date(group.created_at), "dd/MM/yyyy HH:mm:ss")}</td>
                                    <td>
                                        <ButtonContainer>
                                            <div>
                                                <button
                                                    type="button"
                                                    className="btn btn-danger btn-sm"
                                                    onClick={() => {
                                                        remover(group.name)
                                                    }}
                                                >
                                                    Remover
                                                </button>
                                                <Link
                                                    to={`/timeclass/${id_domain}/${group.id}`}
                                                    type="button"
                                                    className="btn btn-warning btn-sm"
                                                >
                                                    Usuários
                                                </Link>
                                                <Link
                                                    to={`/timeclass/${id_domain}/horarios/${group.id}`}
                                                    type="button"
                                                    className="btn btn-warning btn-sm"
                                                >
                                                    Horários
                                                </Link>
                                            </div>
                                        </ButtonContainer>
                                    </td>
                                </tr>
                            )
                        })}
                    </tbody>
                </table>

                <div className="modal" id="modal-add" tabIndex="-1">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">Adicionar grupo</h5>
                                <button
                                    type="button"
                                    className="btn-close"
                                    data-bs-dismiss="modal"
                                    aria-label="Close"
                                ></button>
                            </div>
                            <div className="modal-body">
                                <div className="col-12">
                                    <label htmlFor="grupo" className="form-label">
                                        Nome do Grupo
                                    </label>
                                    <input
                                        id="grupo"
                                        type="text"
                                        className="form-control"
                                        value={grupo}
                                        onChange={(e) => setGrupo(e.target.value)}
                                    />
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">
                                    Fechar
                                </button>
                                <button
                                    type="button"
                                    className="btn btn-primary"
                                    onClick={() => {
                                        adicionarGrupo()
                                    }}
                                >
                                    Adicionar
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Grupos
