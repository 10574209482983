import React, { useState, useEffect } from 'react'
import { FaPlus } from 'react-icons/fa'
import { toast } from 'react-toastify'

import api from '../../../services/apiv3'
import { ButtonContainer } from './styles'

const Operadoras = () => {
    const [operadoras, setOperadoras] = useState([])
    const [inputOperadora, setInputOperadora] = useState('')
    const [inputTag, setInputTag] = useState('')

    const [inputOperadoraEditar, setInputOperadoraEditar] = useState('')
    const [inputTagEditar, setInputTagEditar] = useState('')

    const [operadoraRemover, setOperadoraRemover] = useState({})
    const [operadoraEditar, setOperadoraEditar] = useState({})

    const adicionarOperadora = async () => {
        try {
            await api.post('/carriers', {
                name: inputOperadora,
                tag: inputTag
            })

            toast.success('Operadora Adicionada')
            buscarOperadoras()
        } catch (error) {
            if(error.response){
                toast.error(error.response.data.error)
            }else{
                toast.error(error.message)
            }
        }
    }

    const editarOperadora = async (id) => {
        try {
            await api.put(`/carriers/${id}`, {
                name: inputOperadoraEditar,
                tag: inputTagEditar
            })

            toast.success('Operadora Alterada com Sucesso')
            buscarOperadoras()
        } catch (error) {
            if(error.response){
                toast.error(error.response.data.error)
            }else{
                toast.error(error.message)
            }
        }
    }

    const removerOperadora = async (id) => {
        try {
            await api.delete(`/carriers/${id}`)

            toast.success('Operadora Removida com Sucesso')
            buscarOperadoras()
        } catch (error) {
            if(error.response){
                toast.error(error.response.data.error)
            }else{
                toast.error(error.message)
            }
        }
    }

    const buscarOperadoras = async () => {
        const { data } = await api.get('/carriers')
        setOperadoras(data)
    }

    useEffect(() => {
        buscarOperadoras()
    }, [])

    return (
        <div>
            <br />
            <div className="container">
                {/* <button type="button" className="btn btn-primary"><FaPlus /> Adicionar</button> */}
                <button type="button" className="btn btn-primary" data-bs-toggle="modal" data-bs-target="#modalAddOperadora">
                    <FaPlus /> Adicionar
                </button>
                <table className="table">
                    <thead>
                        <tr>
                            <th scope="col">#</th>
                            <th scope="col">Nome</th>
                            <th scope="col">Tag (BSS)</th>
                            <th scope="col">Adicionado</th>
                            <th scope="col">Atualizado</th>
                            <th scope="col">Ações</th>
                        </tr>
                    </thead>
                    <tbody>
                        {operadoras && operadoras.map(opertadora => {
                            return (
                                <tr key={opertadora.id}>
                                    <th scope="row">{opertadora.id}</th>
                                    <td>{opertadora.name}</td>
                                    <td>{opertadora.tag}</td>
                                    <td>{new Date(opertadora.created_at).toLocaleString()}</td>
                                    <td>{new Date(opertadora.updated_at).toLocaleString()}</td>
                                    <ButtonContainer>
                                        <div>
                                            <button type="button" className="btn btn-danger btn-sm" data-bs-toggle="modal" data-bs-target="#modalRemoverOperadora" onClick={() => {setOperadoraRemover(opertadora)}}>Remover</button>
                                            <button type="button" className="btn btn-warning btn-sm" data-bs-toggle="modal" data-bs-target="#modalEditOperadora" onClick={() => {
                                                setOperadoraEditar(opertadora)
                                                setInputOperadoraEditar(opertadora.name)
                                                setInputTagEditar(opertadora.tag)
                                            }}>Editar</button>
                                        </div>
                                    </ButtonContainer>
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
            </div>


            {/* Modal Adicionar*/}
            <div className="modal fade" id="modalAddOperadora" tabIndex="-1" aria-labelledby="modalAddOperadoraLabel" aria-hidden="true">
            <div className="modal-dialog">
                <div className="modal-content">
                <div className="modal-header">
                    <h5 className="modal-title" id="modalAddOperadoraLabel">Adicionar Operadora</h5>
                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div className="modal-body">
                    <form>
                        <fieldset>
                            <div className="mb-3">
                                <label htmlFor="InputTextOperadora" className="form-label">Nome da Operadora</label>
                                <input type="text" id="InputTextOperadora" className="form-control" placeholder="Operadora" value={inputOperadora} onChange={e => setInputOperadora(e.target.value)}/>
                                </div>
                            <div className="mb-3">
                                <label htmlFor="InputTextTag" className="form-label">Tag da Operadora (Igual o que esta no BSS)</label>
                                <input type="text" id="InputTextTag" className="form-control" placeholder="Tag" value={inputTag} onChange={e => setInputTag(e.target.value)}/>
                            </div>
                        </fieldset>
                    </form>
                </div>
                <div className="modal-footer">
                    <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Fechar</button>
                    <button type="button" className="btn btn-primary" onClick={() => {adicionarOperadora()}}>Adicionar</button>
                </div>
                </div>
            </div>
            </div>

            {/* Modal Editar*/}
            <div className="modal fade" id="modalEditOperadora" tabIndex="-1" aria-labelledby="modalEditOperadoraLabel" aria-hidden="true">
            <div className="modal-dialog">
                <div className="modal-content">
                <div className="modal-header">
                    <h5 className="modal-title" id="modalEditOperadoraLabel">Alterar Operadora</h5>
                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div className="modal-body">
                    <form>
                        <fieldset>
                            <div className="mb-3">
                                <label htmlFor="InputTextOperadora" className="form-label">Nome da Operadora</label>
                                <input type="text" id="InputTextOperadora" className="form-control" placeholder="Operadora" value={inputOperadoraEditar} onChange={e => setInputOperadoraEditar(e.target.value)}/>
                                </div>
                            <div className="mb-3">
                                <label htmlFor="InputTextTag" className="form-label">Tag da Operadora (Igual o que esta no BSS)</label>
                                <input type="text" id="InputTextTag" className="form-control" placeholder="Tag" value={inputTagEditar} onChange={e => setInputTagEditar(e.target.value)}/>
                            </div>
                        </fieldset>
                    </form>
                </div>
                <div className="modal-footer">
                    <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Fechar</button>
                    <button type="button" className="btn btn-warning" data-bs-dismiss="modal" onClick={() => {editarOperadora(operadoraEditar.id)}}>Alterar</button>
                </div>
                </div>
            </div>
            </div>

            {/* Modal Remover */}
            <div className="modal fade" id="modalRemoverOperadora" tabIndex="-1">
                <div className="modal-dialog">
                    <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">Remover Operadora {operadoraRemover.name} ?</h5>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body">
                        <p>Confirmar a removação da Operadora da TAG: {operadoraRemover.tag}</p>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Fechar</button>
                        <button type="button" className="btn btn-danger" data-bs-dismiss="modal" onClick={() => {removerOperadora(operadoraRemover.id)}}>Remover</button>
                    </div>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default Operadoras;
