import React, { useState, useEffect } from 'react'
import { FaPlus } from 'react-icons/fa'
import { toast } from 'react-toastify'

import api from '../../../services/apiv3'
import { ButtonContainer } from './styles'

const DIDs = () => {
    const [dids, setDdids] = useState([])
    const [inputDID, setInputDID] = useState('')

    const [inputDIDEditar, setInputDIDEditar] = useState('')

    const [didRemover, setDidRemover] = useState({})
    const [didEditar, setDidEditar] = useState({})

    const adicionarDID = async () => {
        try {
            await api.post('/dids', {
                did: inputDID,
            })

            toast.success('DID Adicionado')
            buscarDIDs()
        } catch (error) {
            if(error.response){
                toast.error(error.response.data.error)
            }else{
                toast.error(error.message)
            }
        }
    }

    const editarDID = async (id) => {
        try {
            await api.put(`/dids/${id}`, {
                did: inputDIDEditar,
            })

            toast.success('DID Alterado com Sucesso')
            buscarDIDs()
        } catch (error) {
            if(error.response){
                toast.error(error.response.data.error)
            }else{
                toast.error(error.message)
            }
        }
    }

    const removerOperadora = async (id) => {
        try {
            await api.delete(`/dids/${id}`)

            toast.success('DID Removido com Sucesso')
            buscarDIDs()
        } catch (error) {
            if(error.response){
                toast.error(error.response.data.error)
            }else{
                toast.error(error.message)
            }
        }
    }

    const buscarDIDs = async () => {
        const { data } = await api.get('/dids')
        setDdids(data)
    }

    useEffect(() => {
        buscarDIDs()
    }, [])

    return (
        <div>
            <br />
            <div className="container">
                {/* <button type="button" className="btn btn-primary"><FaPlus /> Adicionar</button> */}
                <button type="button" className="btn btn-primary" data-bs-toggle="modal" data-bs-target="#modalAddDID">
                    <FaPlus /> Adicionar
                </button>
                <table className="table">
                    <thead>
                        <tr>
                            <th scope="col">#</th>
                            <th scope="col">DID (Basix)</th>
                            <th scope="col">Adicionado</th>
                            <th scope="col">Atualizado</th>
                            <th scope="col">Ações</th>
                        </tr>
                    </thead>
                    <tbody>
                        {dids && dids.map(did => {
                            return (
                                <tr key={did.id}>
                                    <th scope="row">{did.id}</th>
                                    <td>{did.did}</td>
                                    <td>{new Date(did.created_at).toLocaleString()}</td>
                                    <td>{new Date(did.updated_at).toLocaleString()}</td>
                                    <ButtonContainer>
                                        <div>
                                            <button type="button" className="btn btn-danger btn-sm" data-bs-toggle="modal" data-bs-target="#modalRemoverOperadora" onClick={() => {setDidRemover(did)}}>Remover</button>
                                            <button type="button" className="btn btn-warning btn-sm" data-bs-toggle="modal" data-bs-target="#modalEditDID" onClick={() => {
                                                setDidEditar(did)
                                                setInputDIDEditar(did.did)
                                            }}>Editar</button>
                                        </div>
                                    </ButtonContainer>
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
            </div>

            {/* Modal Adicionar*/}
            <div className="modal fade" id="modalAddDID" tabIndex="-1" aria-labelledby="modalAddDIDLabel" aria-hidden="true">
            <div className="modal-dialog">
                <div className="modal-content">
                <div className="modal-header">
                    <h5 className="modal-title" id="modalAddDIDLabel">Adicionar DID</h5>
                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div className="modal-body">
                    <form>
                        <fieldset>
                            <div className="mb-3">
                                <label htmlFor="InputTextOperadora" className="form-label">DID</label>
                                <input type="text" id="InputTextOperadora" className="form-control" placeholder="DID" value={inputDID} onChange={e => setInputDID(e.target.value)}/>
                                </div>
                        </fieldset>
                    </form>
                </div>
                <div className="modal-footer">
                    <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Fechar</button>
                    <button type="button" className="btn btn-primary" onClick={() => {adicionarDID()}}>Adicionar</button>
                </div>
                </div>
            </div>
            </div>

            {/* Modal Editar*/}
            <div className="modal fade" id="modalEditDID" tabIndex="-1" aria-labelledby="modalEditDIDLabel" aria-hidden="true">
            <div className="modal-dialog">
                <div className="modal-content">
                <div className="modal-header">
                    <h5 className="modal-title" id="modalEditDIDLabel">Alterar DID</h5>
                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div className="modal-body">
                    <form>
                        <fieldset>
                            <div className="mb-3">
                                <label htmlFor="InputTextOperadora" className="form-label">DID</label>
                                <input type="text" id="InputTextOperadora" className="form-control" placeholder="DID" value={inputDIDEditar} onChange={e => setInputDIDEditar(e.target.value)}/>
                                </div>
                        </fieldset>
                    </form>
                </div>
                <div className="modal-footer">
                    <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Fechar</button>
                    <button type="button" className="btn btn-warning" data-bs-dismiss="modal" onClick={() => {editarDID(didEditar.id)}}>Alterar</button>
                </div>
                </div>
            </div>
            </div>

            {/* Modal Remover */}
            <div className="modal fade" id="modalRemoverOperadora" tabIndex="-1">
                <div className="modal-dialog">
                    <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">Remover DID {didRemover.did} ?</h5>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body">
                        <p>Confirmar a remoção do DID: {didRemover.did}</p>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Fechar</button>
                        <button type="button" className="btn btn-danger" data-bs-dismiss="modal" onClick={() => {removerOperadora(didRemover.id)}}>Remover</button>
                    </div>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default DIDs;
