import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { format, parseISO, isBefore, parse } from 'date-fns'
import DatePicker, { registerLocale } from 'react-datepicker'
import { FaArrowLeft, FaPen } from 'react-icons/fa'
import { Link } from 'react-router-dom'
import ptBr from 'date-fns/locale/pt'

import { Form, DataContainer, Original } from './styles'
import api from '../../services/api'
registerLocale('ptBr', ptBr)

const Feriados_Edt = ({ match }) => {
    const { id_domain, id } = match.params

    const [enderecoOriginal1, setEnderecoOriginal1] = useState('')
    const [enderecoOriginal2, setEnderecoOriginal2] = useState('')

    const tipoDestino = ["Usuário", "Grupo", "IVR", "SipTrunk"]

    const [destino, setDestino] = useState()
    const [endereco, setEndereco] = useState()
    const [listaEndereco, setListaEndereco] = useState([])
    const [showEndereco, setShowEndereco] = useState(false)

    const [destino2, setDestino2] = useState()
    const [endereco2, setEndereco2] = useState()
    const [listaEndereco2, setListaEndereco2] = useState([])
    const [showEndereco2, setShowEndereco2] = useState(false)

    const [dominio, setDominio] = useState('')
    const [listaDID, setListaDID] = useState([])
    const [did, setDid] = useState()

    const [startDate, setStartDate] = useState(new Date());
    const ExampleCustomInput = ({ value, onClick }) => (
        <button type="button" className="form-control" onClick={onClick}>
        {value}
        </button>
    );
    const [horaInicio, setHoraInicio] = useState();
    const [minutoInicio, setMinutoInicio] = useState();

    const [endDate, setEndDate] = useState(new Date());
    const [horaTermino, setHoraTermino] = useState();
    const [minutoTermino, setMinutoTermino] = useState();

    const buscarDominio = async () => {
        const { data } = await api.get(`/promptmode/domain/id/${id_domain}`)
        setDominio(data.dominio)
    }

    const preencherDID = async ({ id_domain }) => {
        const { data } = await api.get(`/promptmode/domain/id/${id_domain}`)
        const response = await api.get(`/basix/destination/${data.dominio}/did`)
        setListaDID(response.data)
    }

    const handleAlterar = async () => {
        let falhou = false

        if(!did){
            toast.error('Preencher campo DID')
            falhou = true
        }
        if(!endereco){
            toast.error('Selecione um destino para o inicio do feriado')
            falhou = true
        }
        if(!endereco2){
            toast.error('Selecione um destino para o termino do feriado')
            falhou = true
        }
        if(!horaInicio){
            toast.error('Campo hora do início inválido')
            falhou = true
        }
        if(!minutoInicio){
            toast.error('Campo minuto do início inválido')
            falhou = true
        }
        if(!horaTermino){
            toast.error('Campo hora do termino inválido')
            falhou = true
        }
        if(!minutoTermino){
            toast.error('Campo minuto do termino inválido')
            falhou = true
        }

        const agora = new Date()
        const inicioAntes = isBefore(parse(`${format(startDate, 'dd/MM/yyyy')} ${horaInicio.toString().padStart(2, "0")}:${minutoInicio.toString().padStart(2, "0")}:00`, 'dd/MM/yyyy HH:mm:ss' , new Date()), agora)
        const terminoAntes = isBefore(parse(`${format(endDate, 'dd/MM/yyyy')} ${horaTermino.toString().padStart(2, "0")}:${minutoTermino.toString().padStart(2, "0")}:00`, 'dd/MM/yyyy HH:mm:ss' , new Date()), agora)
        const inicioAntesTermino = isBefore(parse(`${format(startDate, 'dd/MM/yyyy')} ${minutoInicio.toString().padStart(2, "0")}:${minutoInicio.toString().padStart(2, "0")}:00`, 'dd/MM/yyyy HH:mm:ss' , new Date()), parse(`${format(endDate, 'dd/MM/yyyy')} ${horaTermino.toString().padStart(2, "0")}:${minutoTermino.toString().padStart(2, "0")}:00`, 'dd/MM/yyyy HH:mm:ss' , new Date()))

        if(inicioAntes){
            return toast.error('A data inicial do feriado não pode ser inferior a data atual')
        }

        if(terminoAntes){
            return toast.error('A data de termino do feriado não pode ser inferior a data atual')
        }

        if(!inicioAntesTermino){
            return toast.error('A data de inicio deve ser antes da data de termino')
        }

        if(!falhou){
            try {
                const response = await api.put(`/promptmode/feriados/domain/${id_domain}/${id}`, {
                    did,
                    destino: endereco,
                    destino_pos: endereco2,
                    inicio: `${format(startDate, 'dd/MM/yyyy')} ${horaInicio.toString().padStart(2, "0")}:${minutoInicio.toString().padStart(2, "0")}:00`,
                    termino: `${format(endDate, 'dd/MM/yyyy')} ${horaTermino.toString().padStart(2, "0")}:${minutoTermino.toString().padStart(2, "0")}:00`
                })

                toast.success(response.data.message)
            } catch (error) {
                toast.error(error.message)
            }
        }
    }

    const preencherEndereco = async ({ id_domain, endereco }) => {
        setShowEndereco(false)

        if(endereco === "Usuário"){
            const { data } = await api.get(`/promptmode/domain/id/${id_domain}`)
            const response = await api.get(`/basix/destination/${data.dominio}/user`)
            setListaEndereco(response.data)
        }
        if(endereco === "Grupo"){
            const { data } = await api.get(`/promptmode/domain/id/${id_domain}`)
            const response = await api.get(`/basix/destination/${data.dominio}/group`)
            setListaEndereco(response.data)
        }
        if(endereco === "IVR"){
            const { data } = await api.get(`/promptmode/domain/id/${id_domain}`)
            const response = await api.get(`/basix/destination/${data.dominio}/ivr`)
            setListaEndereco(response.data)
        }
        if(endereco === "SipTrunk"){
            const { data } = await api.get(`/promptmode/domain/id/${id_domain}`)
            const response = await api.get(`/basix/destination/${data.dominio}/siptrunk`)
            setListaEndereco(response.data)
        }

        setShowEndereco(true)
    }

    const preencherEndereco2 = async ({ id_domain, endereco }) => {
        setShowEndereco2(false)

        if(endereco === "Usuário"){
            const { data } = await api.get(`/promptmode/domain/id/${id_domain}`)
            const response = await api.get(`/basix/destination/${data.dominio}/user`)
            setListaEndereco2(response.data)
        }
        if(endereco === "Grupo"){
            const { data } = await api.get(`/promptmode/domain/id/${id_domain}`)
            const response = await api.get(`/basix/destination/${data.dominio}/group`)
            setListaEndereco2(response.data)
        }
        if(endereco === "IVR"){
            const { data } = await api.get(`/promptmode/domain/id/${id_domain}`)
            const response = await api.get(`/basix/destination/${data.dominio}/ivr`)
            setListaEndereco2(response.data)
        }
        if(endereco === "SipTrunk"){
            const { data } = await api.get(`/promptmode/domain/id/${id_domain}`)
            const response = await api.get(`/basix/destination/${data.dominio}/siptrunk`)
            setListaEndereco2(response.data)
        }

        setShowEndereco2(true)
    }

    useEffect(() => {
        buscarFeriado()
        buscarDominio()
        preencherDID({ id_domain })
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if(destino){
            preencherEndereco({
                id_domain,
                endereco: destino
            })
        }
    }, [destino, id_domain])

    useEffect(() => {
        if(destino2){
            preencherEndereco2({
                id_domain,
                endereco: destino2
            })
        }
    }, [destino2, id_domain])

    const buscarFeriado = async () => {
        try {
            const response = await api.get(`/promptmode/feriados/domain/${id_domain}/${id}`)
            setDid(response.data.did)
            setEndereco(response.data.destino)
            setEndereco2(response.data.destino_pos)

            setEnderecoOriginal1(response.data.destino)
            setEnderecoOriginal2(response.data.destino_pos)

            let data_1 = parseISO(response.data.inicio)
            let data_2 = parseISO(response.data.fim)

            setStartDate(new Date(response.data.inicio))
            setEndDate(new Date(response.data.fim))

            setHoraInicio(format(data_1, 'HH'))
            setMinutoInicio(format(data_1, 'mm'))

            setHoraTermino(format(data_2, 'HH'))
            setMinutoTermino(format(data_2, 'mm'))
        } catch (error) {
            toast.error(error.message)
        }
    }

    return (
        <div style={{padding: "20px"}}>
            <div className="row justify-content-md-center">
                <div className="col col-lg-4">
                    <Form>
                        <legend>Adicionar Feriado</legend>

                        <div className="mb-3">
                            <div class="row">
                                <div className="col-6">
                                    <label htmlFor="input_dominio" className="form-label">Dominio</label>
                                    <input type="text" className="form-control" id="input_dominio" value={dominio} disabled />
                                </div>

                                <div className="col-6">
                                    <label htmlFor="did" className="form-label">DID</label>
                                    <select id="did" className="form-select" onChange={(e) => {setDid(e.target.value)}} value={did}>
                                        {/* <option></option> */}
                                        {listaDID.map(item => {
                                            if(item.tipo === 'did'){
                                                return (
                                                    <option key={`did-${item.did}`} value={item.did}>{item.did}</option>
                                                )
                                            }else{
                                                return (
                                                    <option key={`did-${item.did}`} value={item.tipo}>{item.did}</option>
                                                )
                                            }
                                        })}
                                    </select>
                                </div>
                            </div>
                        </div>

                        <div className="mb-3">
                            <div class="row">
                                <div className="col-6">
                                    <label htmlFor="destino" className="form-label">Destino - <Original>Original: {enderecoOriginal1}</Original></label>
                                    <select id="destino" className="form-select" onChange={(e) => {setDestino(e.target.value)}} value={destino}>
                                        <option></option>
                                        {tipoDestino.map(item => {
                                            return (
                                                <option key={`destino-${item}`} value={item}>{item}</option>
                                            )
                                        })}
                                    </select>
                                </div>

                                <div className="col-6">
                                    {!!destino && showEndereco && <div className="mb-3">
                                        <label htmlFor="endereco" className="form-label">Endereço</label>
                                        <select id="endereco" className="form-select" onChange={(e) => {setEndereco(e.target.value)}} value={endereco}>
                                            {/* <option></option> */}
                                            {listaEndereco.map(item => {
                                                return (
                                                    <option key={`destino-${item.nome}`} value={item.nome}>{item.nome}</option>
                                                )
                                            })}
                                        </select>
                                    </div>}
                                </div>
                            </div>
                        </div>

                        <div className="mb-3">
                            <div class="row">
                                <div className="col-6">
                                    <label htmlFor="destino2" className="form-label">Destino Pos - <Original>Original: {enderecoOriginal2}</Original></label>
                                    <select id="destino2" className="form-select" onChange={(e) => {setDestino2(e.target.value)}} value={destino2}>
                                        <option></option>
                                        {tipoDestino.map(item => {
                                            return (
                                                <option key={`destino-${item}`} value={item}>{item}</option>
                                            )
                                        })}
                                    </select>
                                </div>

                                <div className="col-6">
                                    {!!destino2 && showEndereco2 && <div className="mb-3">
                                        <label htmlFor="endereco2" className="form-label">Endereço</label>
                                        <select id="endereco2" className="form-select" onChange={(e) => {setEndereco2(e.target.value)}} value={endereco2}>
                                            {/* <option></option> */}
                                            {listaEndereco2.map(item => {
                                                return (
                                                    <option key={`destino-${item.nome}`} value={item.nome}>{item.nome}</option>
                                                )
                                            })}
                                        </select>
                                    </div>}
                                </div>
                            </div>
                        </div>

                        <div className="mb-3">
                            <div class="row">
                                <div className="col-4">
                                    <label htmlFor="data_inicio" className="form-label">Inicio</label>


                                    <DataContainer>
                                        {/* <DatePicker id="data_inicio" className="form-control" dateFormat="dd/MM/yyyy" selected={startDate} onChange={date => setStartDate(date)} /> */}
                                        <DatePicker
                                        id="data_inicio"
                                        className="form-control"
                                        dateFormat="dd/MM/yyyy"
                                        selected={startDate}
                                        onChange={date => setStartDate(date)}
                                        customInput={<ExampleCustomInput />}
                                        locale={'ptBr'}
                                        />
                                    </DataContainer>
                                </div>

                                <div className="col-4">
                                    <label htmlFor="hora_inicio" className="form-label">Hora</label>
                                    <select id="hora_inicio" className="form-select" onChange={(e) => {setHoraInicio(e.target.value)}} value={horaInicio}>
                                        {/* <option></option> */}
                                        {['00', '01', '02', '03', '04', '05', '06', '07', '08', '09', 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23].map(item => {
                                            return (
                                                <option key={`${item}`} value={item}>{item}</option>
                                            )
                                        })}
                                    </select>
                                </div>

                                <div className="col-4">
                                    <label htmlFor="minuto_inicio" className="form-label">Minuto</label>
                                    <select id="minuto_inicio" className="form-select" onChange={(e) => {setMinutoInicio(e.target.value)}} value={minutoInicio}>
                                        {/* <option></option> */}
                                        {['00', '05', 10, 15, 20, 25, 30, 35, 40, 45, 50, 55].map(item => {
                                            return (
                                                <option key={`${item}`} value={item}>{item}</option>
                                            )
                                        })}
                                    </select>
                                </div>
                            </div>
                        </div>

                        <div className="mb-3">
                            <div class="row">
                                <div className="col-4">
                                    <label htmlFor="data_inicio" className="form-label">Termino</label>


                                    <DataContainer>
                                        {/* <DatePicker id="data_inicio" className="form-control" dateFormat="dd/MM/yyyy" selected={startDate} onChange={date => setStartDate(date)} /> */}
                                        <DatePicker
                                        id="data_inicio"
                                        className="form-control"
                                        dateFormat="dd/MM/yyyy"
                                        selected={endDate}
                                        onChange={date => setEndDate(date)}
                                        customInput={<ExampleCustomInput />}
                                        locale={'ptBr'}
                                        />
                                    </DataContainer>
                                </div>

                                <div className="col-4">
                                    <label htmlFor="hora_inicio" className="form-label">Hora</label>
                                    <select id="hora_inicio" className="form-select" onChange={(e) => {setHoraTermino(e.target.value)}} value={horaTermino}>
                                        {/* <option></option> */}
                                        {['00', '01', '02', '03', '04', '05', '06', '07', '08', '09', 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23].map(item => {
                                            return (
                                                <option key={`hora-${item}`} value={item}>{item}</option>
                                            )
                                        })}
                                    </select>
                                </div>

                                <div className="col-4">
                                    <label htmlFor="minuto_inicio" className="form-label">Minuto</label>
                                    <select id="minuto_inicio" className="form-select" onChange={(e) => {setMinutoTermino(e.target.value)}} value={minutoTermino}>
                                        {/* <option></option> */}
                                        {['00', '05', 10, 15, 20, 25, 30, 35, 40, 45, 50, 55].map(item => {
                                            return (
                                                <option key={`hora-${item}`} value={item}>{item}</option>
                                            )
                                        })}
                                    </select>
                                </div>
                            </div>
                        </div>

                        <div className="mb-3"></div>

                        <button type="button" className="btn btn-primary" onClick={() => handleAlterar()}><FaPen /> Alterar</button>
                        {' '}
                        <Link to={`/promptmode/feriados/${id_domain}`} type="button" className="btn btn-warning"><FaArrowLeft /> Voltar</Link>
                    </Form>
                </div>
            </div>
        </div>
    )
}

export default Feriados_Edt
