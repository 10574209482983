import React, { useState, useEffect, useContext } from 'react'
import { toast } from 'react-toastify';
import { FaSignInAlt } from 'react-icons/fa'

import { Title, ContainerLogo } from './styles'
import logo from '../../assets/images/logo.webp'
import { AuthContext } from '../../context/AuthContext'

const Login = () => {
    const [usuario, setUsuario] = useState('');
    const [password, setPassword] = useState('');
    const { signIn } = useContext(AuthContext)

    const [errorUsuario, setErrorUsuario] = useState(false);
    const [errorPassword, setErrorPassword] = useState(false);

    const loginHandler = async (e) => {
        e.preventDefault();

        if(usuario === '' && password === ''){
            toast.error(`usuário ou senha inválidos`);
        }else{
            const [user, domain] = usuario.split('@')

            try {
                signIn({
                    user,
                    domain,
                    password
                })
            } catch (error) {
                if(error.response){
                    toast.error(error.response.data.error);
                }else{
                    toast.error(error.message);
                }
            }
        }
        
    }

    const verificarErros = () => {
        if(usuario === ''){
            setErrorUsuario('Campo usuário deve ser preenchido');
        }else{
            if(/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,})+$/.test(usuario)){
                setErrorUsuario(false)
            }else{
                setErrorUsuario('Dados inválidos');
            }
        }

        if(password === ''){
            setErrorPassword('Campo senha deve ser preenchido');
        }else{
            // if(password.length < 8){
            //     setErrorPassword('Campo senha deve ter ao menos 8 caracteres');
            // }else{
            //     setErrorPassword(false)
            // }
            setErrorPassword(false)
        }

        if(errorUsuario === false && errorPassword === false){
            return true
        }else{
            return false
        }
    }

    useEffect(() => {
        verificarErros()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [usuario, password])

    useEffect(() => {
        setErrorPassword(false)
        setErrorUsuario(false)
    }, [])

    return (
        <>
            <div className="container">
                <div className="row justify-content-md-center">
                    <div className="col-12 col-sm-12">
                        <ContainerLogo>
                            <img src={logo} alt="imagem logo"/>
                        </ContainerLogo>
                    </div>
                    <div className="col-12 col-sm-12">
                        <Title>Painel Cloud Comunicação</Title>
                    </div>
                    <div className="col-xs-8 col-sm-4">
                        
                        <form>
                            <div className="mb-3">
                                <label htmlFor="usuario" className="form-label">Usuário</label>
                                <input type="text" className={`form-control ${errorUsuario !== false ? "is-invalid" : ""}`} id="usuario" aria-describedby="emailHelp" value={usuario} onChange={e => setUsuario(e.target.value)}/>
                                { errorUsuario !== false && <div className="invalid-feedback">
                                    {errorUsuario}
                                </div> }
                            </div>
                            <div className="mb-3">
                                <label htmlFor="password" className="form-label">Senha</label>
                                <input type="password" className={`form-control ${errorPassword !== false ? "is-invalid" : ""}`} id="password" value={password} onChange={e => setPassword(e.target.value)}/>
                                { errorPassword !== false && <div className="invalid-feedback">
                                    {errorPassword}
                                </div> }
                            </div>
                            <button type="submit" className="btn btn-primary" disabled={`${errorUsuario !== false || errorPassword !== false ? "disabled" : ""}`} onClick={loginHandler}>Entrar <FaSignInAlt /></button>
                        </form>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Login;