import React, { useState, useEffect } from 'react'
import { toast } from 'react-toastify'
import { FaEdit, FaWindowClose, FaUsers, FaPlus } from 'react-icons/fa'
import { useHistory } from "react-router-dom"

import api from '../../../services/apiv3'
import { ButtonContainer, TrCustom, DivFormCheck } from './styles'

const Zendesk = () => {
  const history = useHistory()

  const [domainsIntegracao, setDomainsIntegracao] = useState([])
  const [allDomains, setAllDomains] = useState([])

  const [selectAddDomain, setSelectAddDomain] = useState('')

  const [updateDomainID, setUpdateDomainID] = useState(0)
  const [updateDomainName, setUpdateDomainName] = useState('')
  const [updateActiveDomain, setUpdateActiveDomain] = useState(false)

  const [removerDomainID, setRemoverDomainID] = useState(0)
  const [removerDomainName, setRemoverDomainName] = useState('')

  const buscarTodosDominios = async () => {
    try {
      const { data } = await api.get('/basix/domains')
      setAllDomains(data)
    } catch (error) {
      toast.error(error.message)
    }
  }

  const buscarDominios = async () => {
    try {
      const { data } = await api.get('/basix/integracao/zendesk/domain')
      setDomainsIntegracao(data)
    } catch (error) {
      toast.error(error.message)
    }
  }

  const adicionarDominio = async () => {
    try {
      if (selectAddDomain) {
        await api.post('/basix/integracao/zendesk/domain', {
          domain: selectAddDomain
        })

        document.getElementById('btnCloseModal').click();
        toast.success(`Domínio: ${selectAddDomain} adicionado`)
        await buscarDominios()

        setTimeout(() => {
          setSelectAddDomain('')
        }, 1)
      }
    } catch (error) {
      toast.error(error.message)
    }
  }

  const alterarDominio = async () => {
    try {
      await api.put(`/basix/integracao/zendesk/domain/${updateDomainID}`, {
        domain: updateDomainName,
        active: updateActiveDomain
      })

      document.getElementById('btnCloseModalAlterar').click();
      toast.success(`Domínio: ${updateDomainName} alterado`)
      await buscarDominios()

      setTimeout(() => {
        setUpdateDomainID(0)
        setUpdateDomainName('')
        setUpdateActiveDomain(false)
      }, 1)
    } catch (error) {
      toast.error(error.message)
    }
  }

  const removerDominio = async () => {
    try {
      await api.delete(`/basix/integracao/zendesk/domain/${removerDomainID}`)
      document.getElementById('btnCloseModalRemover').click();
      toast.success(`Domínio: ${removerDomainName} removido`)
      await buscarDominios()

      setTimeout(() => {
        setRemoverDomainID(0)
        setRemoverDomainName('')
      }, 1)
    } catch (error) {
      toast.error(error.message)
    }
  }

  useEffect(() => {
    buscarDominios()
    buscarTodosDominios()
  }, [])

  return (
    <div className="container">
      <ButtonContainer>
        <button type="button" className="btn btn-primary" data-bs-toggle="modal" data-bs-target="#modal_adicionar"><FaPlus /> Adicionar</button>
      </ButtonContainer>
      <table className="table">
        <thead>
          <tr>
            <th scope="col" style={{ width: "10%" }}>ID</th>
            <th scope="col" style={{ width: "50%" }}>Domínio</th>
            <th scope="col" style={{ width: "10%" }}>Ativo</th>
            <th scope="col" style={{ width: "30%" }}></th>
          </tr>
        </thead>
        <tbody>
          {
            domainsIntegracao.length > 0 && domainsIntegracao.map(item => {
              return (
                <TrCustom key={item.id}>
                  <th scope="row">{item.id}</th>
                  <td>{item.domain}</td>
                  <td>{item.active ? 'Sim' : 'Não'}</td>
                  <td className='buttons'>
                    <button type="button" className="btn btn-warning btn-sm" data-bs-toggle="modal" data-bs-target="#modal_alterar" onClick={() => {
                      setUpdateDomainID(item.id)
                      setUpdateDomainName(item.domain)
                      setUpdateActiveDomain(item.active)
                    }}><FaEdit /> Alterar</button>{' '}
                    <button type="button" className="btn btn-danger btn-sm" data-bs-toggle="modal" data-bs-target="#modal_remover" onClick={() => {
                      setRemoverDomainID(item.id)
                      setRemoverDomainName(item.domain)
                    }}><FaWindowClose /> Remover</button>{' '}
                    <button type="button" className="btn btn-info btn-sm" onClick={() => {
                      history.push(`/integracao/zendesk/${item.id}`)
                    }}><FaUsers /> Usuários</button>{' '}
                  </td>
                </TrCustom>
              )
            })
          }
        </tbody>
      </table>

      {/* Modal Adicionar */}
      <div className="modal fade" id="modal_adicionar" tabIndex="-1" aria-labelledby="modal_adicionar_label" aria-hidden="true">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="modal_adicionar_label">Adicionar Domínio</h1>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body">
              <div className="mb-3">
                <select className="form-select" aria-label="Default select example" value={selectAddDomain} onChange={e => setSelectAddDomain(e.target.value)}>
                  <option>Selecione um domínio</option>
                  {allDomains && allDomains.filter(item => !(domainsIntegracao.map(item2 => item2.domain).includes(item.VCH_DOMAIN))).map(item => {
                    return (
                      <option key={`${item.id}${item.VCH_DOMAIN}`} value={item.VCH_DOMAIN}>{item.VCH_DOMAIN}</option>
                    )
                  })}
                </select>
              </div>
            </div>
            <div className="modal-footer">
              <button id='btnCloseModal' type="button" className="btn btn-secondary" data-bs-dismiss="modal">Fechar</button>
              <button type="button" className="btn btn-primary" onClick={(() => { adicionarDominio() })}>Adicionar</button>
            </div>
          </div>
        </div>
      </div>

      {/* Modal Alterar */}
      <div className="modal fade" id="modal_alterar" tabIndex="-1" aria-labelledby="modal_alterar_label" aria-hidden="true">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="modal_alterar_label">Alterar Domínio</h1>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body">
              <div className="mb-3">
                <label htmlFor="inputUpdateDomainName" className="form-label">Domínio</label>
                <input type="text" className="form-control" id="inputUpdateDomainName" value={updateDomainName} disabled />
              </div>

              <DivFormCheck>
                <div className="form-check">
                  <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" checked={updateActiveDomain} onChange={e => setUpdateActiveDomain(e.target.checked)} />
                  <label className="form-check-label" htmlFor="flexCheckDefault">
                    Ativo
                  </label>
                </div>
              </DivFormCheck>
            </div>
            <div className="modal-footer">
              <button id='btnCloseModalAlterar' type="button" className="btn btn-secondary" data-bs-dismiss="modal">Fechar</button>
              <button type="button" className="btn btn-primary" onClick={(() => { alterarDominio() })}>Alterar</button>
            </div>
          </div>
        </div>
      </div>

      {/* Modal Remover */}
      <div className="modal fade" id="modal_remover" tabIndex="-1" aria-labelledby="modal_remover_label" aria-hidden="true">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="modal_remover_label">Remover Domínio</h1>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body">
              <div className="mb-3">
                <label htmlFor="inputUpdateDomainName" className="form-label">Domínio</label>
                <input type="text" className="form-control" id="inputUpdateDomainName" value={removerDomainName} disabled />
              </div>
            </div>
            <div className="modal-footer">
              <button id='btnCloseModalRemover' type="button" className="btn btn-secondary" data-bs-dismiss="modal">Fechar</button>
              <button type="button" className="btn btn-danger" onClick={(() => { removerDominio() })}>Remover</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Zendesk;
