import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { FaPencilAlt, FaTrashAlt, FaPlus } from 'react-icons/fa'

import { ContainerReportGroup, ContainerReportGroupList, ButtonsTd } from './style'

import api from '../../../../services/apiv3'

const List = () => {
  const [domains, setDomains] = useState([])
  const [groups, setGroups] = useState([])
  const [reportGroups, setReportGroups] = useState([])

  const [intDomainKey, setIntDomainKey] = useState(0)
  const [intReportGroupKey, setIntReportGroupKey] = useState(0)

  const [reportGroupName, setReportGroupName] = useState('')
  const [reportGroupsEnable, setReportGroupsEnable] = useState([])

  const getDomainsCallCenter = async () => {
    try {
      const { data } = await api.get('/callcenter/domains')
      setDomains(data)
    } catch (error) {
      toast.error(error.message)
    }
  }

  const getReportGroups = async () => {
    try {
      if (intDomainKey) {
        const { data } = await api.get(`/callcenter/reportgroups/${intDomainKey}`)
        setReportGroups(data)
      } else {
        setReportGroups([])
      }
    } catch (error) {
      toast.error(error.message)
    }
  }

  const getGroups = async () => {
    try {
      if (intDomainKey) {
        const { data } = await api.get(`/callcenter/groups/${intDomainKey}`)
        setGroups(data)
      } else {
        setGroups([])
      }
    } catch (error) {
      toast.error(error.message)
    }
  }

  const addReportGroup = async () => {
    try {
      if (reportGroupName) {
        if (reportGroupsEnable.length > 0) {
          const { data } = await api.post(`/callcenter/reportgroups/${intDomainKey}`, {
            VCH_GROUPS: reportGroupsEnable.join(','),
            VCH_NAME: reportGroupName
          })
          toast.success(data.message)

          getReportGroups()
          getGroups()
        } else {
          toast.error('Selecione um grupo para adicionar o relátorio de grupo')
        }
      } else {
        toast.error('Preencher o nome do relátorio de grupo')
      }
    } catch (error) {
      toast.error(error.message)
    }
  }

  const updateReportGroup = async () => {
    try {
      if (reportGroupName) {
        if (reportGroupsEnable.length > 0) {
          const { data } = await api.put(`/callcenter/reportgroups/${intDomainKey}/${intReportGroupKey}`, {
            VCH_GROUPS: reportGroupsEnable.join(','),
            VCH_NAME: reportGroupName
          })
          toast.success(data.message)

          setIntReportGroupKey(0)
          getReportGroups()
          getGroups()
        } else {
          toast.error('Selecione um grupo para adicionar o relátorio de grupo')
        }
      } else {
        toast.error('Preencher o nome do relátorio de grupo')
      }
    } catch (error) {
      toast.error(error.message)
    }
  }

  const deleteReportGroup = async () => {
    try {
      if (intReportGroupKey) {
        const { data } = await api.delete(`/callcenter/reportgroups/${intDomainKey}/${intReportGroupKey}`)
        toast.success(data.message)

        setIntReportGroupKey(0)
        getReportGroups()
        getGroups()
      }
    } catch (error) {
      toast.error(error.message)
    }
  }

  useEffect(() => {
    getDomainsCallCenter()
  }, [])

  useEffect(() => {
    getReportGroups()
    getGroups()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [intDomainKey])


  return (
    <ContainerReportGroup>
      <div className="container-fluid text-center">
        <div className="row align-items-start">
          <div className="col">
            <select className="form-select" aria-label="Default select example" value={intDomainKey} onChange={e => setIntDomainKey(e.target.value)}>
              <option defaultValue={true}></option>
              {domains.map(item => {
                return (
                  <option key={item.INT_DOMAIN_KEY} value={item.INT_DOMAIN_KEY}>{item.DOMINIO}</option>
                )
              })}
            </select>
          </div>
          <div className="col">

          </div>
          <div className="col">

          </div>
          <div className="col">

          </div>
          <div className="col">

          </div>
        </div>
      </div>
      <ContainerReportGroupList>
        {/*  */}

        <button type="button" className="btn btn-primary" data-bs-toggle="modal" data-bs-target="#modalAddOperadora" onClick={() => {
          setReportGroupsEnable([])
          setReportGroupName('')
        }}>Adicionar <FaPlus /></button>
        <table className="table table-striped">
          <thead>
            <tr>
              <th scope="col">ID</th>
              <th scope="col">Grupos</th>
              <th scope="col">Nome da aba</th>
              <th scope="col">Ações</th>
            </tr>
          </thead>
          <tbody>
            {reportGroups.map(item => {
              return (
                <tr key={item.INT_REPORTGROUP_KEY}>
                  <th className='align-middle' scope="row">{item.INT_REPORTGROUP_KEY}</th>
                  <td className='align-middle'>{(item.VCH_GROUPS && groups.length > 0) ? item.VCH_GROUPS.split(',').map(item => groups.find(itemg => itemg.INT_GROUP_KEY === Number(item))?.VCH_NAME).join(' ') : ''}</td>
                  <td className='align-middle'>{item.VCH_NAME}</td>
                  <ButtonsTd>
                    <button type="button" className="btn btn-warning btn-sm" data-bs-toggle="modal" data-bs-target="#modalEditReportGroup" onClick={() => {
                      setReportGroupName(item.VCH_NAME)
                      setReportGroupsEnable(item.VCH_GROUPS.split(','))
                      setIntReportGroupKey(item.INT_REPORTGROUP_KEY)
                    }}>Editar <FaPencilAlt /></button>
                    <button type="button" className="btn btn-danger btn-sm" data-bs-toggle="modal" data-bs-target="#modalDeleteReportGroup" onClick={() => {
                      setIntReportGroupKey(item.INT_REPORTGROUP_KEY)
                    }}>Remover <FaTrashAlt /></button>
                  </ButtonsTd>
                </tr>
              )
            })}
          </tbody>

        </table>
      </ContainerReportGroupList>

      {/* Modal Adicionar */}
      <div className="modal fade" id="modalAddOperadora" tabIndex="-1" aria-labelledby="modalAddOperadoraLabel" aria-hidden="true">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="modalAddOperadoraLabel">Adicionar Relátorio Total</h5>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body">
              <form>
                <fieldset>
                  <div className="mb-3">
                    <label htmlFor="InputTextReportGroupName" className="form-label">Nome</label>
                    <input type="text" id="InputTextReportGroupName" className="form-control" placeholder="Nome" value={reportGroupName} onChange={e => setReportGroupName(e.target.value)} />
                  </div>
                  <div className="mb-3">
                    <label htmlFor="InputTextTag" className="form-label">Grupos</label>
                    {groups && groups.map(item => {
                      return (
                        <div className="form-check" key={item.INT_GROUP_KEY}>
                          <input className="form-check-input" type="checkbox" value={item.INT_GROUP_KEY} id="flexCheckChecked" onChange={e => {
                            if (e.target.checked) {
                              setReportGroupsEnable([...reportGroupsEnable, e.target.value])
                            } else {
                              setReportGroupsEnable(reportGroupsEnable.filter(item3 => Number(item3) !== Number(e.target.value)))
                            }
                          }} checked={reportGroupsEnable.find(item2 => Number(item2) === Number(item.INT_GROUP_KEY)) ? true : false} />
                          <label className="form-check-label" htmlFor="flexCheckChecked">
                            {item.VCH_NAME}
                          </label>
                        </div>
                      )
                    })}
                  </div>
                </fieldset>
              </form>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Fechar</button>
              <button type="button" className="btn btn-primary" data-bs-dismiss="modal" onClick={() => {
                addReportGroup()
              }}>Adicionar</button>
            </div>
          </div>
        </div>
      </div>

      {/* Modal Editar */}
      <div className="modal fade" id="modalEditReportGroup" tabIndex="-1" aria-labelledby="modalEditReportGroupLabel" aria-hidden="true">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="modalEditReportGroupLabel">Alterar Relátorio Total</h5>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body">

              <form>
                <fieldset>
                  <div className="mb-3">
                    <label htmlFor="InputTextReportGroupName" className="form-label">Nome</label>
                    <input type="text" id="InputTextReportGroupName" className="form-control" placeholder="Nome" value={reportGroupName} onChange={e => setReportGroupName(e.target.value)} />
                  </div>
                  <div className="mb-3">
                    <label htmlFor="InputTextTag" className="form-label">Grupos</label>
                    {groups && groups.map(item => {
                      return (
                        <div className="form-check" key={item.INT_GROUP_KEY}>
                          <input className="form-check-input" type="checkbox" value={item.INT_GROUP_KEY} id="flexCheckChecked" onChange={e => {
                            if (e.target.checked) {
                              setReportGroupsEnable([...reportGroupsEnable, e.target.value])
                            } else {
                              setReportGroupsEnable(reportGroupsEnable.filter(item3 => Number(item3) !== Number(e.target.value)))
                            }
                          }} checked={reportGroupsEnable.find(item2 => Number(item2) === Number(item.INT_GROUP_KEY)) ? true : false} />
                          <label className="form-check-label" htmlFor="flexCheckChecked">
                            {item.VCH_NAME}
                          </label>
                        </div>
                      )
                    })}
                  </div>
                </fieldset>
              </form>

            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Fechar</button>
              <button type="button" className="btn btn-primary" data-bs-dismiss="modal" onClick={() => {
                updateReportGroup()
              }}>Alterar</button>
            </div>
          </div>
        </div>
      </div>

      {/* Modal Remover */}
      <div className="modal fade" id="modalDeleteReportGroup" tabIndex="-1" aria-labelledby="modalDeleteReportGroupLabel" aria-hidden="true">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="modalDeleteReportGroupLabel">Remover Relátorio Total</h5>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body">
              Remover configuração <strong>{(reportGroups.length > 0 && intReportGroupKey) ? reportGroups.find(item => Number(item.INT_REPORTGROUP_KEY) === Number(intReportGroupKey))?.VCH_NAME : ''}</strong> ?
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Fechar</button>
              <button type="button" className="btn btn-primary" data-bs-dismiss="modal" onClick={() => {
                deleteReportGroup()
              }}>Remover</button>
            </div>
          </div>
        </div>
      </div>
    </ContainerReportGroup >
  )
}

export default List;
