import React, { useEffect, useState, useContext } from 'react'
import { Link } from 'react-router-dom'
import { toast } from 'react-toastify'
import { FaBuilding, FaRegBuilding, FaPlus, FaMinus } from 'react-icons/fa'

import api from '../../services/api'
import { AuthContext } from '../../context/AuthContext'
import { Container, Item } from './styles'

const PromptMode = () => {
  const { username } = useContext(AuthContext)
  const [dominios, setDominios] = useState([])
  const [dominiosPM, setDominiosPM] = useState([])
  const [busca, setBusca] = useState('')

  const listarDominiosBasix = async () => {
    try {
      const { data } = await api.get('/basix/domains')
      setDominios(data)
    } catch (error) {
      if (error.response) {
        toast.error(error.response.data.error)
      } else {
        toast.error(error.message)
      }
    }

  }

  const listarDominiosPromptMode = async () => {
    try {
      const { data } = await api.get('/promptmode/domains')
      setDominiosPM(data)
    } catch (error) {
      if (error.response) {
        toast.error(error.response.data.error)
      } else {
        toast.error(error.message)
      }
    }
  }

  const adicionarDominio = async (dominio) => {
    try {
      await api.post('/promptmode/domain', {
        username,
        domain: dominio
      })

      listarDominiosBasix()
      listarDominiosPromptMode()

      toast.success(`Domínio ${dominio} adicionado com sucesso`)
    } catch (error) {
      if (error.response) {
        toast.error(error.response.data.error)
      } else {
        toast.error(error.message)
      }
    }
  }

  const removerDominio = async (id) => {
    try {
      const { data } = await api.delete(`/promptmode/domain/${id}`)

      listarDominiosBasix()
      listarDominiosPromptMode()

      toast.success(data.message)
    } catch (error) {
      if (error.response) {
        toast.error(error.response.data.error)
      } else {
        toast.error(error.message)
      }
    }
  }

  useEffect(() => {
    listarDominiosBasix()
    listarDominiosPromptMode()
  }, [])

  return (
    <>
      <Container>
        <Item>
          <input className="form-control me-2" type="search" placeholder="Busca" aria-label="Busca" value={busca} onChange={e => setBusca(e.target.value)} />
        </Item>
        {dominios.filter(element => element.domain.includes(busca)).map(element => {
          const encontrado = dominiosPM.find(item => item.dominio === element.domain)

          if (encontrado) {
            return (
              <Item key={element.int_domain_key}>
                <Link to={`/promptmode/${encontrado.id}`}><span className="nome"><FaRegBuilding /> {element.domain}</span></Link>
                <div className="actions">
                  <button type="button" className="btn btn-danger" onClick={() => removerDominio(encontrado.id)}><FaMinus /> Remover</button>
                </div>
              </Item>
            )
          } else {
            return (
              <Item key={element.int_domain_key}>
                <span className="nome"><FaBuilding /> {element.domain}</span>
                <div className="actions">
                  <button type="button" className="btn btn-success" onClick={() => adicionarDominio(element.domain)}><FaPlus /> Adicionar</button>
                </div>
              </Item>
            )
            // return (
            //   <></>
            // )
          }
        })}
      </Container>
    </>
  )
}

export default PromptMode;
