/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useContext } from "react"
import { Link } from "react-router-dom"

import { AuthContext } from "../../context/AuthContext"
import { Username } from "./styles"

const Header = () => {
  const { nome, logout } = useContext(AuthContext)

  const handleLogout = (e) => {
    e.preventDefault()
    logout()
  }

  return (
    <nav className="navbar navbar-expand-lg navbar-light bg-light">
      <div className="container-fluid">
        <Link className="navbar-brand" to="/">
          Painel Cloud
        </Link>
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav me-auto mb-2 mb-lg-0">
            <li className="nav-item">
              <Link className="nav-link active" aria-current="page" to="/promptmode">
                PromptMode
              </Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link active" aria-current="page" to="/timeclass">
                Bloqueio de Ramal
              </Link>
            </li>
            {/* <li className="nav-item">
                            <Link className="nav-link active" aria-current="page" to="/callcenter">
                                CallCenter
                            </Link>
                        </li> */}
            <li className="nav-item dropdown">
              <a className="nav-link dropdown-toggle active" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                Roteamento
              </a>
              <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                <li><Link className="dropdown-item" to="/carriers">Operadoras</Link></li>
                <li><Link className="dropdown-item" to="/prefixes">Prefixos</Link></li>
                <li><Link className="dropdown-item" to="/dids">DIDs</Link></li>
                <li><hr className="dropdown-divider" /></li>
                <li><Link className="dropdown-item" to="/routingByDestination">Rotas por destino</Link></li>
                <li><Link className="dropdown-item" to="/overwrite">Sobreposição</Link></li>
              </ul>
            </li>
            <li className="nav-item">
              <Link className="nav-link active" aria-current="page" to="/gswave">
                GsWave
              </Link>
            </li>

            <li className="nav-item dropdown">
              <a className="nav-link dropdown-toggle active" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                CallCenter
              </a>
              <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                <li><Link className="dropdown-item" to="/callcenter/reportgroup">Relatório Total</Link></li>
              </ul>
            </li>

            <li className="nav-item dropdown">
              <a className="nav-link dropdown-toggle active" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                Integração
              </a>
              <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                <li><Link className="dropdown-item" to="/integracao/zendesk">Zendesk</Link></li>
                <li><Link className="dropdown-item" to="/integracao/botchat">{`Bot <-> Chat`}</Link></li>
              </ul>
            </li>


            {/* <li className="nav-item">
                        <a className="nav-link" href="/link">Link</a>
                    </li>
                    <li className="nav-item dropdown">
                        <a className="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                            Dropdown
                        </a>
                        <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                            <li><a className="dropdown-item" href="/action">Action</a></li>
                            <li><a className="dropdown-item" href="/action2">Another action</a></li>
                            <li><hr className="dropdown-divider" /></li>
                            <li><a className="dropdown-item" href="/else">Something else here</a></li>
                        </ul>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link disabled" href="/disable" aria-disabled="true">Disabled</a>
                    </li> */}
          </ul>
          <form className="d-flex">
            <Username>{nome}</Username>
            <button className="btn btn-outline-danger" onClick={handleLogout}>
              logout
            </button>
          </form>
        </div>
      </div>
    </nav>
  )
}

export default Header
