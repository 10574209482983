import React, { useState, useEffect } from 'react'
import { FaPlus } from 'react-icons/fa'
import { toast } from 'react-toastify'

import api from '../../../services/apiv3'
import { ButtonContainer } from './styles'

const Prefixos = () => {
    const [prefixos, setPrefixos] = useState([])
    const [inputPrefixo, setInputPrefixo] = useState('')
    const [inputDescricao, setInputDescricao] = useState('')

    const [inputPrefixoEditar, setInputPrefixoEditar] = useState('')
    const [inputDescricaoEditar, setInputDescricaoEditar] = useState('')

    const [prefixoRemover, setPrefixoRemover] = useState({})
    const [prefixoEditar, setPrefixoEditar] = useState({})

    const buscarPrefixos = async () => {
        const { data } = await api.get('/prefixes')
        setPrefixos(data)
    }

    const adicionarPrefixo = async () => {
        try {
            await api.post('/prefixes', {
                prefix: inputPrefixo,
                description: inputDescricao
            })

            toast.success('Prefixo Adicionado')
            buscarPrefixos()
        } catch (error) {
            if(error.response){
                toast.error(error.response.data.error)
            }else{
                toast.error(error.message)
            }
        }
    }

    const editarPrefixo = async (id) => {
        try {
            await api.put(`/prefixes/${id}`, {
                prefix: inputPrefixoEditar,
                description: inputDescricaoEditar
            })

            toast.success('Prefixo Alterado com Sucesso')
            buscarPrefixos()
        } catch (error) {
            if(error.response){
                toast.error(error.response.data.error)
            }else{
                toast.error(error.message)
            }
        }
    }

    const removerPrefixo = async (id) => {
        try {
            await api.delete(`/prefixes/${id}`)

            toast.success('Prefixo Removido com Sucesso')
            buscarPrefixos()
        } catch (error) {
            if(error.response){
                toast.error(error.response.data.error)
            }else{
                toast.error(error.message)
            }
        }
    }

    useEffect(() => {
        buscarPrefixos()
    }, [])

    return (
        <div>
            <br />
            <div className="container">
                <button type="button" className="btn btn-primary" data-bs-toggle="modal" data-bs-target="#modalAddPrefix">
                    <FaPlus /> Adicionar
                </button>
                <table className="table">
                    <thead>
                        <tr>
                            <th scope="col">#</th>
                            <th scope="col">Prefixo (ASTPP)</th>
                            <th scope="col">Descrição</th>
                            <th scope="col">Adicionado</th>
                            <th scope="col">Atualizado</th>
                            <th scope="col">Ações</th>
                        </tr>
                    </thead>
                    <tbody>
                        {prefixos && prefixos.map(prefixo => {
                            return (
                                <tr key={prefixo.id}>
                                    <th scope="row">{prefixo.id}</th>
                                    <td>{prefixo.prefix}</td>
                                    <td>{prefixo.description}</td>
                                    <td>{new Date(prefixo.created_at).toLocaleString()}</td>
                                    <td>{new Date(prefixo.updated_at).toLocaleString()}</td>
                                    <ButtonContainer>
                                        <div>
                                            <button type="button" className="btn btn-danger btn-sm" data-bs-toggle="modal" data-bs-target="#modalRemoverPrefixo" onClick={() => {setPrefixoRemover(prefixo)}}>Remover</button>
                                            <button type="button" className="btn btn-warning btn-sm" data-bs-toggle="modal" data-bs-target="#modalPrefixOperadora" onClick={() => {
                                                setPrefixoEditar(prefixo)
                                                setInputPrefixoEditar(prefixo.prefix)
                                                setInputDescricaoEditar(prefixo.description)
                                            }}>Editar</button>
                                        </div>
                                    </ButtonContainer>
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
            </div>

            {/* Modal Adicionar*/}
            <div className="modal fade" id="modalAddPrefix" tabIndex="-1" aria-labelledby="modalAddPrefixoLabel" aria-hidden="true">
            <div className="modal-dialog">
                <div className="modal-content">
                <div className="modal-header">
                    <h5 className="modal-title" id="modalAddPrefixoLabel">Adicionar Prefixo</h5>
                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div className="modal-body">
                    <form>
                        <fieldset>
                            <div className="mb-3">
                                <label htmlFor="InputTextPrefixo" className="form-label">Prefixo</label>
                                <input type="text" id="InputTextPrefixo" className="form-control" placeholder="Prefixo" value={inputPrefixo} onChange={e => setInputPrefixo(e.target.value)}/>
                                </div>
                            <div className="mb-3">
                                <label htmlFor="InputTextTag" className="form-label">Descrição</label>
                                <input type="text" id="InputTextTag" className="form-control" placeholder="Descrição" value={inputDescricao} onChange={e => setInputDescricao(e.target.value)}/>
                            </div>
                        </fieldset>
                    </form>
                </div>
                <div className="modal-footer">
                    <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Fechar</button>
                    <button type="button" className="btn btn-primary" onClick={() => {adicionarPrefixo()}}>Adicionar</button>
                </div>
                </div>
            </div>
            </div>

            {/* Modal Editar*/}
            <div className="modal fade" id="modalPrefixOperadora" tabIndex="-1" aria-labelledby="modalEditOperadoraLabel" aria-hidden="true">
            <div className="modal-dialog">
                <div className="modal-content">
                <div className="modal-header">
                    <h5 className="modal-title" id="modalEditOperadoraLabel">Alterar Prefixo</h5>
                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div className="modal-body">
                    <form>
                        <fieldset>
                            <div className="mb-3">
                                <label htmlFor="InputTextOperadora" className="form-label">Prefixo</label>
                                <input type="text" id="InputTextOperadora" className="form-control" placeholder="Operadora" value={inputPrefixoEditar} onChange={e => setInputPrefixoEditar(e.target.value)}/>
                                </div>
                            <div className="mb-3">
                                <label htmlFor="InputTextTag" className="form-label">Descrição</label>
                                <input type="text" id="InputTextTag" className="form-control" placeholder="Tag" value={inputDescricaoEditar} onChange={e => setInputDescricaoEditar(e.target.value)}/>
                            </div>
                        </fieldset>
                    </form>
                </div>
                <div className="modal-footer">
                    <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Fechar</button>
                    <button type="button" className="btn btn-warning" data-bs-dismiss="modal" onClick={() => {editarPrefixo(prefixoEditar.id)}}>Alterar</button>
                </div>
                </div>
            </div>
            </div>

            {/* Modal Remover */}
            <div className="modal fade" id="modalRemoverPrefixo" tabIndex="-1">
                <div className="modal-dialog">
                    <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">Remover Prefixo {prefixoRemover.prefix} ?</h5>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body">
                        <p>Confirmar a remoção do Prefixo: {prefixoRemover.description}</p>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Fechar</button>
                        <button type="button" className="btn btn-danger" data-bs-dismiss="modal" onClick={() => {removerPrefixo(prefixoRemover.id)}}>Remover</button>
                    </div>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default Prefixos;
