import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { toast } from 'react-toastify'

import api from '../../services/api'
import { Form } from './styles'

const Programacoes_Add = ({ match }) => {
    const { id_domain } = match.params
    const semana = ["Domingo", "Segunda", "Terça", "Quarta", "Quinta", "Sexta", "Sabado", "Segunda a Sexta", "Segunda a Sabado", "Segunda a Domingo"]
    const tipoDestino = ["Usuário", "Grupo", "IVR", "SipTrunk"]
    const [listaHora, setListaHora ] = useState([])
    const [listaMinuto, setListaMinuto ] = useState([])

    const [hora, setHora] = useState()
    const [minuto, setMinuto] = useState()
    const [diaSemana, setDiaSemana] = useState()
    const [destino, setDestino] = useState()
    const [endereco, setEndereco] = useState()
    const [listaEndereco, setListaEndereco] = useState([])
    const [listaDID, setListaDID] = useState([])
    const [did, setDid] = useState()

    const [dominio, setDominio] = useState('')
    const [showEndereco, setShowEndereco] = useState(false)

    useEffect(() => {
        if(destino){
            preencherEndereco({
                id_domain,
                endereco: destino
            })
        }
    }, [destino, id_domain])

    useEffect(() => {
        const lista1 = []
        const lista2 = []

        for (let i = 0; i <= 23; i++) {
            lista1.push(i)
        }
        setListaHora(lista1)
    
        for (let i = 0; i <= 55; i=i+5) {
            lista2.push(i)
        }
        setListaMinuto(lista2)
        preencherDID({ id_domain })
        buscarDominio()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const buscarDominio = async () => {
        const { data } = await api.get(`/promptmode/domain/id/${id_domain}`)
        setDominio(data.dominio)
    }

    const preencherDID = async ({ id_domain }) => {
        const { data } = await api.get(`/promptmode/domain/id/${id_domain}`)
        const response = await api.get(`/basix/destination/${data.dominio}/did`)
        setListaDID(response.data)
    }

    const preencherEndereco = async ({ id_domain, endereco }) => {
        setShowEndereco(false)

        if(endereco === "Usuário"){
            const { data } = await api.get(`/promptmode/domain/id/${id_domain}`)
            const response = await api.get(`/basix/destination/${data.dominio}/user`)
            setListaEndereco(response.data)
        }
        if(endereco === "Grupo"){
            const { data } = await api.get(`/promptmode/domain/id/${id_domain}`)
            const response = await api.get(`/basix/destination/${data.dominio}/group`)
            setListaEndereco(response.data)
        }
        if(endereco === "IVR"){
            const { data } = await api.get(`/promptmode/domain/id/${id_domain}`)
            const response = await api.get(`/basix/destination/${data.dominio}/ivr`)
            setListaEndereco(response.data)
        }
        if(endereco === "SipTrunk"){
            const { data } = await api.get(`/promptmode/domain/id/${id_domain}`)
            const response = await api.get(`/basix/destination/${data.dominio}/siptrunk`)
            setListaEndereco(response.data)
        }

        setShowEndereco(true)
    }

    const handleAdicionar = async () => {
        if(diaSemana <= 6){
            try {
                const response = await api.post(`/promptmode/domain/${id_domain}`, {
                    did,
                    destino: endereco,
                    dia_semana: diaSemana,
                    hora,
                    minuto
                })

                toast.success(`Programação adicionado com ID: ${response.data[0]}`)
            } catch (error) {
                toast.error(error.message)
            }
        }

        if(diaSemana === '7'){
            try {
                for (let i = 1; i <= 5; i++) {
                    await api.post(`/promptmode/domain/${id_domain}`, {
                        did,
                        destino: endereco,
                        dia_semana: i,
                        hora,
                        minuto
                    })
                }

                toast.success(`Programação adicionada com sucesso`)
            } catch (error) {
                toast.error(error.message)
            }
        }

        if(diaSemana === '8'){
            try {
                for (let i = 1; i <= 6; i++) {
                    await api.post(`/promptmode/domain/${id_domain}`, {
                        did,
                        destino: endereco,
                        dia_semana: i,
                        hora,
                        minuto
                    })
                }

                toast.success(`Programação adicionada com sucesso`)
            } catch (error) {
                toast.error(error.message)
            }
        }

        if(diaSemana === '9'){
            try {
                for (let i = 0; i <= 6; i++) {
                    await api.post(`/promptmode/domain/${id_domain}`, {
                        did,
                        destino: endereco,
                        dia_semana: i,
                        hora,
                        minuto
                    })
                }

                toast.success(`Programação adicionada com sucesso`)
            } catch (error) {
                toast.error(error.message)
            }
        }
    }    

    return (
        <div style={{padding: "20px"}}>
            <div className="row justify-content-md-center">
                <div className="col col-lg-4">
                    
                <Form>
                    <fieldset>
                        <legend>Adicionar Programação</legend>

                        <div className="mb-3">
                            <label htmlFor="input_dominio" className="form-label">Dominio</label>
                            <input type="text" className="form-control" id="input_dominio" value={dominio} disabled />
                        </div>

                        <div className="mb-3">
                            <label htmlFor="did" className="form-label">DID</label>
                            <select id="did" className="form-select" onChange={(e) => {setDid(e.target.value)}} value={did}>
                                <option></option>
                                {listaDID.map(item => {
                                    if(item.tipo === 'did'){
                                        return (
                                            <option key={`did-${item.did}`} value={item.did}>{item.did}</option>
                                        )
                                    }else{
                                        return (
                                            <option key={`did-${item.did}`} value={item.tipo}>{item.did}</option>
                                        )
                                    }
                                })}
                            </select>
                        </div>

                        <div className="mb-3">
                            <label htmlFor="destino" className="form-label">Destino</label>
                            <select id="destino" className="form-select" onChange={(e) => {setDestino(e.target.value)}} value={destino}>
                                <option></option>
                                {tipoDestino.map(item => {
                                    return (
                                        <option key={`destino-${item}`} value={item}>{item}</option>
                                    )
                                })}
                            </select>
                        </div>

                        {!!destino && showEndereco && <div className="mb-3">
                            <label htmlFor="endereco" className="form-label">Endereço</label>
                            <select id="endereco" className="form-select" onChange={(e) => {setEndereco(e.target.value)}} value={endereco}>
                                <option></option>
                                {listaEndereco.map(item => {
                                    return (
                                        <option key={`destino-${item.nome}`} value={item.nome}>{item.nome}</option>
                                    )
                                })}
                            </select>
                        </div>}

                        <div className="mb-3">
                            <label htmlFor="select-semana" className="form-label">Dia da semana</label>
                            <select id="select-semana" className="form-select" onChange={(e) => {setDiaSemana(e.target.value)}} value={diaSemana}>
                                <option></option>
                                {semana.map((item, index) => {
                                    return (
                                        <option key={`semana-${item}`} value={index}>{item}</option>
                                    )
                                })}
                            </select>
                        </div>

                        <div className="input-group">
                            <select id="select-hora" className="form-select" onChange={(e) => {setHora(e.target.value)}} value={hora}>
                                <option></option>
                                {listaHora.map(item => {
                                    return (
                                        <option key={`hora-${item}`} value={item}>{item}</option>
                                    )
                                })}
                            </select>

                            <select id="select-minuto" className="form-select" onChange={(e) => {setMinuto(e.target.value)}} value={minuto}>
                                <option></option>
                                {listaMinuto.map(item => {
                                    return (
                                        <option key={`minuto-${item}`} value={item}>{item}</option>
                                    )
                                })}
                            </select>
                        </div>
                    </fieldset>

                    <div className="mb-3"></div>

                    <button type="button" className="btn btn-primary" onClick={() => handleAdicionar()}>Adicionar <i className="fas fa-plus"></i></button>
                    {' '}
                    <Link to={`/promptmode/${id_domain}`} type="button" className="btn btn-warning">Voltar</Link>
                </Form>

                </div>
            </div>
        </div>
    )
}

export default Programacoes_Add