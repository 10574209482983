import styled from 'styled-components'

export const Segunda = styled.tr`
    background-color: #0466c8;

    th {
        color: #F0F0F5;
    }
    td {
        color: #F0F0F5;
    }
`;

export const Terca = styled.tr`
    background-color: #0353a4;

    th {
        color: #F0F0F5;
    }
    td {
        color: #F0F0F5;
    }
`;

export const Quarta = styled.tr`
    background-color: #023e7d;

    th {
        color: #F0F0F5;
    }
    td {
        color: #F0F0F5;
    }
`;

export const Quinta = styled.tr`
    background-color: #002855;

    th {
        color: #F0F0F5;
    }
    td {
        color: #F0F0F5;
    }
`;

export const Sexta = styled.tr`
    background-color: #001845;

    th {
        color: #F0F0F5;
    }
    td {
        color: #F0F0F5;
    }
`;

export const Sabado = styled.tr`
    background-color: #001233;

    th {
        color: #F0F0F5;
    }
    td {
        color: #F0F0F5;
    }
`;

export const Domingo = styled.tr`
    background-color: #33415c;

    th {
        color: #F0F0F5;
    }
    td {
        color: #F0F0F5;
    }
`;

export const Separador = styled.tr`
    background-color: #fa7921;

    th {
        color: #F0F0F5;
    }
    td {
        color: #F0F0F5;
    }
`;

export const ButtonContainer = styled.td`
    padding: 0px !important;

    div {
        display: flex;
        justify-content: space-around;
        padding: 0px !important;

        a {
            margin: 6px;
            margin-left: 2.5px;
            margin-right: 2.5px;
            
            height: 25px;
            line-height: 12.5px;
            font-size: 16px;
        }

        button {
            margin: 6px;
            margin-left: 2.5px;
            margin-right: 2.5px;
            
            height: 25px;
            line-height: 12.5px;
            font-size: 16px;
        }
    }
`;