import styled from "styled-components"

export const ContainerReportGroup = styled.div`
    padding: 20px 0px;
`

export const ContainerReportGroupList = styled.div`
    padding: 20px 20px;
`

export const ButtonsTd = styled.td`
    button {
        margin-right: 5px;
    }
`
