import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { FaArrowLeft, FaPlus } from 'react-icons/fa'
import { toast } from 'react-toastify'
import { format } from 'date-fns'

import api from '../../services/api'
import { ButtonContainer, Separador } from './styles'

const Feriados = ({ match }) => {
    const { id_domain } = match.params

    const [programacoes, setProgramacoes] = useState([])

    useEffect(() => {
        buscarFeriados()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const buscarFeriados = async () => {
        try {
            const response = await api.get(`/promptmode/feriados/domain/${id_domain}`)
            setProgramacoes(response.data)
        } catch (error) {
            toast.error(error.message)
        }
    }

    const handleDelete = async (id) => {
        try {
            const response = await api.delete(`/promptmode/feriados/domain/${id_domain}/${id}`)
            toast.success(response.data.message)
            buscarFeriados()
        } catch (error) {
            toast.error(error.message)
        }
    }

    return (
        <div style={{padding: "20px"}}>
            <div className="row justify-content-md-center">
                <div className="col col-lg-8">
                    <Link to={`/promptmode/${id_domain}`} type="button" className="btn btn-warning"><FaArrowLeft /> Voltar</Link>
                    {' '}
                    <Link to={`/promptmode/feriados/${id_domain}/adicionar`} type="button" className="btn btn-primary"><FaPlus /> Adicionar</Link>
                    <table className="table">
                        <thead>
                            <tr>
                                <th scope="col">#</th>
                                <th scope="col">DID</th>
                                <th scope="col">Destino</th>
                                <th scope="col">Dia da semana</th>
                                <th scope="col">Hora</th>
                                <th scope="col">Minuto</th>
                                <th scope="col">Ações</th>
                            </tr>
                        </thead>
                        <tbody>
                            {programacoes.map((item, index, arr) =>{
                                if(index !== 0 ){
                                    if(item.inicio !== arr[index-1].inicio){
                                        return (
                                            <>
                                                <Separador>&nbsp;</Separador>
                                                <Separador>&nbsp;</Separador>
                                                <Separador>&nbsp;</Separador>
                                                <Separador>&nbsp;</Separador>
                                                <Separador>&nbsp;</Separador>
                                                <Separador>&nbsp;</Separador>
                                                <Separador>&nbsp;</Separador>
                                                <tr key={item.id}>
                                                    <th scope="row">{item.id}</th>
                                                    <td>{item.did}</td>
                                                    <td>{item.destino}</td>
                                                    <td>{item.destino_pos}</td>
                                                    <td>{format(new Date(item.inicio), 'dd/MM/yyyy HH:mm:ss')}</td>
                                                    <td>{format(new Date(item.fim), 'dd/MM/yyyy HH:mm:ss')}</td>
                                                    <ButtonContainer>
                                                        <div>
                                                            <button type="button" className="btn btn-danger btn-sm" onClick={() => { handleDelete(item.id) }}>Remover</button>
                                                            <Link to={`/promptmode/feriados/${id_domain}/${item.id}`} type="button" className="btn btn-warning btn-sm" >Editar</Link>
                                                        </div>
                                                    </ButtonContainer>
                                                </tr>
                                            </>
                                        )
                                    }
                                }
                                

                                return (
                                    <tr key={item.id}>
                                        <th scope="row">{item.id}</th>
                                        <td>{item.did}</td>
                                        <td>{item.destino}</td>
                                        <td>{item.destino_pos}</td>
                                        <td>{format(new Date(item.inicio), 'dd/MM/yyyy HH:mm:ss')}</td>
                                        <td>{format(new Date(item.fim), 'dd/MM/yyyy HH:mm:ss')}</td>
                                        <ButtonContainer>
                                            <div>
                                                <button type="button" className="btn btn-danger btn-sm" onClick={() => { handleDelete(item.id) }}>Remover</button>
                                                <Link to={`/promptmode/feriados/${id_domain}/${item.id}`} type="button" className="btn btn-warning btn-sm" >Editar</Link>
                                            </div>
                                        </ButtonContainer>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    )
}

export default Feriados