import React, { useEffect, useState, useContext } from "react"
import { Link } from "react-router-dom"
import { FaPlus, FaArrowLeft } from "react-icons/fa"
import { toast } from "react-toastify"

import api from "../../../services/api"
import { AuthContext } from "../../../context/AuthContext"
import { ButtonContainer } from "./styles"

const Horarios = ({ match }) => {
    const semana_dia = [0, 1, 2, 3, 4, 5, 6]
    const semana = ["Domingo", "Segunda", "Terça", "Quarta", "Quinta", "Sexta", "Sabado"]
    const { username } = useContext(AuthContext)
    const { id_domain, id_group } = match.params
    const [domain, setDomain] = useState({})
    const [group, setGroup] = useState({})
    const [rules, setRules] = useState([])

    const [diaSemana, setDiaSemana] = useState(0)
    const [hora, setHora] = useState(8)
    const [minuto, setMinuto] = useState(0)

    const horas = []
    for (let i = 0; i <= 23; i++) {
        horas.push(i)
    }

    const minutos = []
    for (let i = 0; i <= 60; i++) {
        minutos.push(i)
    }

    const [classId, setClassId] = useState("")
    const [classes, setClasses] = useState([])

    const buscarDadosDominio = async () => {
        try {
            const { data } = await api.get(`/timeclass/domain/findById/${id_domain}`)
            setDomain(data)
        } catch (error) {
            toast.error(`Falha ao buscar dados do domínio`)
        }
    }

    const buscarDadosGrupo = async () => {
        try {
            const { data } = await api.get(`/timeclass/domain/${domain.domain}/group/findById/${id_group}`, {
                headers: { username }
            })
            setGroup(data)
        } catch (error) {
            toast.error(`Falha ao buscar grupos do domínio`)
        }
    }

    const buscarRegras = async () => {
        try {
            const { data } = await api.get(`/timeclass/domain/${domain.domain}/group/${group.name}/rule`, {
                headers: { username }
            })
            setRules(data)
        } catch (error) {
            toast.error(`Falha ao buscar grupos do domínio`)
        }
    }

    const buscarClasses = () => {
        return new Promise(async (resolve, reject) => {
            try {
                const { data } = await api.get(`/timeclass/serviceclass`, {
                    headers: { username }
                })

                const { data: domainData } = await api.get(`/timeclass/serviceclass/domain/${domain.domain}`, {
                    headers: { username }
                })
                setClasses([...data, ...domainData])
                resolve()
            } catch (error) {
                toast.error(`Falha ao buscar classes`)
                reject(error)
            }
        })
    }

    const remover = async (id) => {
        try {
            await api.delete(`/timeclass/domain/${domain.domain}/group/${group.name}/rule/${id}`, {
                headers: {
                    username
                }
            })

            toast.success(`Regra removida`)
            buscarDadosGrupo()
        } catch (error) {
            toast.error(error.response.data.error)
        }
    }

    const adicionarProgramacao = async () => {
        try {
            const selectedClass = classes.find((item) => {
                console.log(`${item.INT_SERVICECLASS_KEY} - ${classId}`)
                if (item.INT_SERVICECLASS_KEY.toString() === classId) {
                    return true
                }
                return false
            })

            await api.post(
                `/timeclass/domain/${domain.domain}/group/${group.name}/rule`,
                {
                    dia_semana: diaSemana,
                    hora: hora,
                    minuto: minuto,
                    service_class_name: selectedClass.VCH_NAME,
                    service_class_key: selectedClass.INT_SERVICECLASS_KEY
                },
                {
                    headers: {
                        username
                    }
                }
            )

            toast.success(`Regra adicionada`)
            buscarDadosGrupo()
        } catch (error) {
            toast.error(error.response.data.error)
        }
    }

    useEffect(() => {
        buscarDadosDominio()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (domain.domain) {
            buscarDadosGrupo()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [domain])

    useEffect(() => {
        if (group.name) {
            buscarRegras()
            buscarClasses()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [group])

    return (
        <>
            <div className="container" style={{ paddingTop: "20px" }}>
                <div style={{ paddingBottom: "10px" }}>
                    <Link to={`/timeclass/${id_domain}`} type="button" className="btn btn-warning">
                        <FaArrowLeft /> Voltar
                    </Link>{" "}
                    <button
                        type="button"
                        data-bs-toggle="modal"
                        data-bs-target="#modal-add"
                        className="btn btn-primary"
                    >
                        <FaPlus /> Adicionar
                    </button>
                </div>

                <div className="alert alert-light" role="alert">
                    Horarios do grupo: <strong>{group.name}</strong>
                </div>

                <table className="table">
                    <thead>
                        <tr>
                            <th scope="col">#</th>
                            <th scope="col">Dia da Semana</th>
                            <th scope="col">Hora</th>
                            <th scope="col">Minuto</th>
                            <th scope="col">Classe Serviço</th>
                            <th scope="col">Ações</th>
                        </tr>
                    </thead>
                    <tbody>
                        {rules.map((rul) => {
                            return (
                                <tr key={rul.id}>
                                    <td>{rul.id}</td>
                                    <td>{semana[rul.dia_semana]}</td>
                                    <td>{rul.hora}</td>
                                    <td>{rul.minuto}</td>
                                    <td>{rul.service_class_name}</td>
                                    <td>
                                        <ButtonContainer>
                                            <div>
                                                <button
                                                    type="button"
                                                    className="btn btn-danger btn-sm"
                                                    onClick={() => {
                                                        remover(rul.id)
                                                    }}
                                                >
                                                    Remover
                                                </button>
                                                {/* <Link
                                                    to={`/timeclass/${id_domain}/${group.id}`}
                                                    type="button"
                                                    className="btn btn-warning btn-sm"
                                                >
                                                    Usuários
                                                </Link>
                                                <Link
                                                    to={`/timeclass/${id_domain}/${group.id}`}
                                                    type="button"
                                                    className="btn btn-warning btn-sm"
                                                >
                                                    Horários
                                                </Link> */}
                                            </div>
                                        </ButtonContainer>
                                    </td>
                                </tr>
                            )
                        })}
                    </tbody>
                </table>

                <div className="modal" id="modal-add" tabIndex="-1">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">Adicionar programacao</h5>
                                <button
                                    type="button"
                                    className="btn-close"
                                    data-bs-dismiss="modal"
                                    aria-label="Close"
                                ></button>
                            </div>
                            <div className="modal-body">
                                <div className="col-12">
                                    <label htmlFor="dia_semana" className="form-label">
                                        Dia da Semana
                                    </label>
                                    <select
                                        id="dia_semana"
                                        className="form-select"
                                        onChange={(e) => {
                                            setDiaSemana(e.target.value)
                                        }}
                                        value={diaSemana}
                                    >
                                        <option></option>
                                        {semana_dia.map((item) => {
                                            return (
                                                <option key={item} value={item}>
                                                    {semana[item]}
                                                </option>
                                            )
                                        })}
                                    </select>
                                </div>

                                <div className="col-12">
                                    <label htmlFor="hora" className="form-label">
                                        Hora
                                    </label>
                                    <select
                                        id="hora"
                                        className="form-select"
                                        onChange={(e) => {
                                            setHora(e.target.value)
                                        }}
                                        value={hora}
                                    >
                                        <option></option>
                                        {horas.map((item) => {
                                            return (
                                                <option key={item} value={item}>
                                                    {item}
                                                </option>
                                            )
                                        })}
                                    </select>
                                </div>

                                <div className="col-12">
                                    <label htmlFor="minuto" className="form-label">
                                        Minutos
                                    </label>
                                    <select
                                        id="minuto"
                                        className="form-select"
                                        onChange={(e) => {
                                            setMinuto(e.target.value)
                                        }}
                                        value={minuto}
                                    >
                                        <option></option>
                                        {minutos.map((item) => {
                                            return (
                                                <option key={item} value={item}>
                                                    {item}
                                                </option>
                                            )
                                        })}
                                    </select>
                                </div>

                                <div className="col-12">
                                    <label htmlFor="classe" className="form-label">
                                        Classe de Serviço
                                    </label>
                                    <select
                                        id="classe"
                                        className="form-select"
                                        onChange={(e) => {
                                            setClassId(e.target.value)
                                        }}
                                        value={classId}
                                    >
                                        <option></option>
                                        {classes.map((item) => {
                                            return (
                                                <option
                                                    key={item.INT_SERVICECLASS_KEY}
                                                    value={item.INT_SERVICECLASS_KEY}
                                                >
                                                    {item.VCH_NAME}
                                                </option>
                                            )
                                        })}
                                    </select>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">
                                    Fechar
                                </button>
                                <button
                                    type="button"
                                    className="btn btn-primary"
                                    onClick={() => {
                                        adicionarProgramacao()
                                    }}
                                >
                                    Adicionar
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Horarios
