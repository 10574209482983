import styled from 'styled-components'

export const ButtonContainer = styled.td`
    padding: 0px !important;

    div {
        display: flex;
        justify-content: space-around;
        padding: 0px !important;

        /* button {
            margin: 6px;
            margin-left: 2.5px;
            margin-right: 2.5px;

            height: 25px;
            line-height: 12.5px;
            font-size: 16px;
        } */

        button {
            margin: 6px;
            margin-left: 2.5px;
            margin-right: 2.5px;

            height: 25px;
            line-height: 12.5px;
            font-size: 16px;
        }
    }
`;
