import styled from "styled-components";

export const ButtonContainer = styled.div`
  margin: 0.5em;
`

export const TrCustom = styled.tr`
  td.buttons  {
    padding: 2px;
  }
`

export const DivFormCheck = styled.div`
  div.form-check {
    display: flex;
    align-items: center;
    input {
      margin-top: 0;
      padding: 0;
    }

    label {
      margin-left: 10px;
    }
  }
`
