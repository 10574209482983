import React, { useState, useEffect } from 'react'
import { FaPlus } from 'react-icons/fa'
import { toast } from 'react-toastify'

import api from '../../../services/apiv3'
import { ButtonContainer } from './styles'

const RoutingByDestination = () => {
    const [routes, setRoutes] = useState([])

    const [carriers, setCarriers] = useState([])
    const [selectOperadora, setSelectOperadora] = useState(null)
    const [prefixes, setPrefixes] = useState([])
    const [selectPrefixo, setSelectPrefixo] = useState(null)

    const [inputPriority, setInputPriority] = useState(1)
    const [inputRegex, setInputRegex] = useState('.*')
    const [inputPriorityRegex, setInputPriorityRegex] = useState(1)

    const [inputPriorityEditar, setInputPriorityEditar] = useState(1)
    const [inputRegexEditar, setInputRegexEditar] = useState('.*')
    const [inputPriorityRegexEditar, setInputPriorityRegexEditar] = useState(1)

    const [routeRemover, setRouteRemover] = useState({})
    const [routeEditar, setRouteEditar] = useState({})

    const buscarRotas = async () => {
        const { data: listPrefixes } = await api.get('/prefixes')
        setPrefixes(listPrefixes)

        const { data: listCarriers } = await api.get('/carriers')
        setCarriers(listCarriers)

        const { data: listRouting } = await api.get('/routing')
        setRoutes(listRouting)
    }

    const adicionarRota = async () => {
        try {
            await api.post('/routing', {
                fk_id_carrier: selectOperadora,
                fk_id_prefix: selectPrefixo,
                priority: inputPriority,
                regex: inputRegex,
                priority_regex: inputPriorityRegex
            })

            toast.success('Rota Adicionada')
            buscarRotas()
        } catch (error) {
            if(error.response){
                toast.error(error.response.data.error)
            }else{
                toast.error(error.message)
            }
        }
    }

    const alterarRota = async (id) => {
        try {
            await api.put(`/routing/${id}`, {
                fk_id_carrier: selectOperadora,
                fk_id_prefix: selectPrefixo,
                priority: inputPriorityEditar,
                regex: inputRegexEditar,
                priority_regex: inputPriorityRegexEditar
            })

            toast.success('Rota Alterada com Sucesso')
            buscarRotas()
        } catch (error) {
            if(error.response){
                toast.error(error.response.data.error)
            }else{
                toast.error(error.message)
            }
        }
    }

    const removerRoute = async (id) => {
        try {
            await api.delete(`/routing/${id}`)

            toast.success('Rota Removida com Sucesso')
            buscarRotas()
        } catch (error) {
            if(error.response){
                toast.error(error.response.data.error)
            }else{
                toast.error(error.message)
            }
        }
    }

    useEffect(() => {
        buscarRotas()
    }, [])

    return (
        <div>
            <br />
            <div className="container">
                <button type="button" className="btn btn-primary" data-bs-toggle="modal" data-bs-target="#modalAddRoute">
                    <FaPlus /> Adicionar
                </button>
                <table className="table">
                    <thead>
                        <tr>
                            <th scope="col">#</th>
                            <th scope="col">Operadora</th>
                            <th scope="col">Rota</th>
                            <th scope="col">Prioridade</th>
                            <th scope="col">Regex</th>
                            <th scope="col">Prioridade do Regex</th>
                            <th scope="col">Adicionado</th>
                            <th scope="col">Atualizado</th>
                            <th scope="col">Ações</th>
                        </tr>
                    </thead>
                    <tbody>
                        {routes && routes.map(route => {
                            return (
                                <tr key={route.id}>
                                    <th scope="row">{route.id}</th>
                                    <td>{route.Carrier.name} - {route.Carrier.tag}</td>
                                    <td>{route.Prefix.prefix} - {route.Prefix.description}</td>
                                    <td>{route.priority}</td>
                                    <td>{route.regex}</td>
                                    <td>{route.priority_regex}</td>
                                    <td>{new Date(route.created_at).toLocaleString()}</td>
                                    <td>{new Date(route.updated_at).toLocaleString()}</td>
                                    <ButtonContainer>
                                        <div>
                                            <button type="button" className="btn btn-danger btn-sm" data-bs-toggle="modal" data-bs-target="#modalRemoverRota" onClick={() => {setRouteRemover(route)}}>Remover</button>
                                            <button type="button" className="btn btn-warning btn-sm" data-bs-toggle="modal" data-bs-target="#modaAlterarRota" onClick={() => {
                                                setRouteEditar(route)
                                                setInputPriorityEditar(route.priority)
                                                setInputRegexEditar(route.regex)
                                                setInputPriorityRegexEditar(route.priority_regex)

                                                setSelectOperadora(route.Carrier.id)
                                                setSelectPrefixo(route.Prefix.id)
                                            }}>Editar</button>
                                        </div>
                                    </ButtonContainer>
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
            </div>

            {/* Modal Adicionar*/}
            <div className="modal fade" id="modalAddRoute" tabIndex="-1" aria-labelledby="modalAddPrefixoLabel" aria-hidden="true">
            <div className="modal-dialog">
                <div className="modal-content">
                <div className="modal-header">
                    <h5 className="modal-title" id="modalAddPrefixoLabel">Adicionar Rota</h5>
                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div className="modal-body">
                    <form>
                        <fieldset>
                            <div className="mb-3">
                                <label htmlFor="SelectOperadora" className="form-label">Operadora</label>
                                <select id="SelectOperadora" class="form-select" placeholder="Operadora" value={selectOperadora} onChange={e => setSelectOperadora(e.target.value)}>
                                    <option>Selecione uma Operadora</option>
                                    {carriers && carriers.map(op => {
                                        return (
                                            <option value={op.id}>{op.name}</option>
                                        )
                                    })}
                                </select>

                                </div>
                            <div className="mb-3">
                                <label htmlFor="SelectPrefixo" className="form-label">Prefixo</label>
                                <select id="SelectPrefixo" class="form-select" placeholder="Operadora" value={selectPrefixo} onChange={e => setSelectPrefixo(e.target.value)}>
                                    <option>Selecione um Prefixo</option>
                                    {prefixes && prefixes.map(pr => {
                                        return (
                                            <option value={pr.id}>{pr.prefix}</option>
                                        )
                                    })}
                                </select>
                            </div>
                            <div className="mb-3">
                                <label htmlFor="SelectPrioridade_1" className="form-label">Prioridade</label>
                                <select id="SelectPrioridade_1" class="form-select" placeholder="Operadora" value={inputPriority} onChange={e => setInputPriority(e.target.value)}>
                                    {[1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20].map(item => {
                                        return (
                                            <option value={item}>{item}</option>
                                        )
                                    })}
                                </select>
                            </div>
                            <div className="mb-3">
                                <label htmlFor="InputTextRegex" className="form-label">Regex</label>
                                <input type="text" id="InputTextRegex" className="form-control" placeholder="Regex" value={inputRegex} onChange={e => setInputRegex(e.target.value)}/>
                            </div>
                            <div className="mb-3">
                                <label htmlFor="SelectPrioridade_2" className="form-label">Prioridade do Regex</label>
                                <select id="SelectPrioridade_2" class="form-select" placeholder="Operadora" value={inputPriorityRegex} onChange={e => setInputPriorityRegex(e.target.value)}>
                                    {[1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20].map(item => {
                                        return (
                                            <option value={item}>{item}</option>
                                        )
                                    })}
                                </select>
                            </div>
                        </fieldset>
                    </form>
                </div>
                <div className="modal-footer">
                    <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Fechar</button>
                    <button type="button" className="btn btn-primary" onClick={() => {adicionarRota()}}>Adicionar</button>
                </div>
                </div>
            </div>
            </div>

            {/* Modal Alterar*/}
            <div className="modal fade" id="modaAlterarRota" tabIndex="-1" aria-labelledby="modalAddPrefixoLabel" aria-hidden="true">
            <div className="modal-dialog">
                <div className="modal-content">
                <div className="modal-header">
                    <h5 className="modal-title" id="modalAddPrefixoLabel">Alterar Rota</h5>
                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div className="modal-body">
                    <form>
                        <fieldset>
                            <div className="mb-3">
                                <label htmlFor="SelectOperadora" className="form-label">Operadora</label>
                                <select id="SelectOperadora" class="form-select" placeholder="Operadora" value={selectOperadora} onChange={e => setSelectOperadora(e.target.value)}>
                                    <option>Selecione uma Operadora</option>
                                    {carriers && carriers.map(op => {
                                        return (
                                            <option value={op.id}>{op.name}</option>
                                        )
                                    })}
                                </select>

                            </div>
                            <div className="mb-3">
                                <label htmlFor="SelectPrefixo" className="form-label">Prefixo</label>
                                <select id="SelectPrefixo" class="form-select" placeholder="Operadora" value={selectPrefixo} onChange={e => setSelectPrefixo(e.target.value)}>
                                    <option>Selecione um Prefixo</option>
                                    {prefixes && prefixes.map(pr => {
                                        return (
                                            <option value={pr.id}>{pr.prefix}</option>
                                        )
                                    })}
                                </select>
                            </div>
                            <div className="mb-3">
                                <label htmlFor="SelectPrioridade_1" className="form-label">Prioridade</label>
                                <select id="SelectPrioridade_1" class="form-select" placeholder="Operadora" value={inputPriorityEditar} onChange={e => setInputPriorityEditar(e.target.value)}>
                                    {[1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20].map(item => {
                                        return (
                                            <option value={item}>{item}</option>
                                        )
                                    })}
                                </select>
                            </div>
                            <div className="mb-3">
                                <label htmlFor="InputTextRegex" className="form-label">Regex</label>
                                <input type="text" id="InputTextRegex" className="form-control" placeholder="Regex" value={inputRegexEditar} onChange={e => setInputRegexEditar(e.target.value)}/>
                            </div>
                            <div className="mb-3">
                                <label htmlFor="SelectPrioridade_2" className="form-label">Prioridade do Regex</label>
                                <select id="SelectPrioridade_2" class="form-select" placeholder="Operadora" value={inputPriorityRegexEditar} onChange={e => setInputPriorityRegexEditar(e.target.value)}>
                                    {[1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20].map(item => {
                                        return (
                                            <option value={item}>{item}</option>
                                        )
                                    })}
                                </select>
                            </div>
                        </fieldset>
                    </form>
                </div>
                <div className="modal-footer">
                    <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Fechar</button>
                    <button type="button" className="btn btn-warning" onClick={() => {alterarRota(routeEditar.id)}}>Alterar</button>
                </div>
                </div>
            </div>
            </div>

            {/* Modal Remover */}
            <div className="modal fade" id="modalRemoverRota" tabIndex="-1">
                <div className="modal-dialog">
                    <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">Remover Rota {routeRemover?.Carrier?.name} -&gt; {routeRemover?.Prefix?.description}?</h5>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body">
                        <p>Confirmar a remoção do Rota: {routeRemover.priority} {routeRemover.regex} {routeRemover.priority_regex}</p>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Fechar</button>
                        <button type="button" className="btn btn-danger" data-bs-dismiss="modal" onClick={() => {removerRoute(routeRemover.id)}}>Remover</button>
                    </div>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default RoutingByDestination;
