import React, { useState, useEffect } from 'react'
import { FaPlus } from 'react-icons/fa'
import { toast } from 'react-toastify'

import api from '../../../services/apiv3'
import { ButtonContainer } from './styles'

const Overwrite = () => {
    const [overwrites, setOverwrites] = useState([])

    const [dids, setDids] = useState([])
    const [selectDid, setSelectDid] = useState(null)
    const [prefixes, setPrefixes] = useState([])
    const [selectPrefixo, setSelectPrefixo] = useState(null)

    const [inputPriority, setInputPriority] = useState(1)

    const [inputPriorityEditar, setInputPriorityEditar] = useState(1)

    const [overwriteRemover, setOverwriteRemover] = useState({})
    const [overwriteEditar, setOverwriteEditar] = useState({})

    const buscarSobrescritas = async () => {
        const { data: listPrefixes } = await api.get('/prefixes')
        setPrefixes(listPrefixes)

        const { data: listDids } = await api.get('/dids')
        setDids(listDids)

        const { data: listRouting } = await api.get('/overwrite')
        setOverwrites(listRouting)
    }

    const adicionarSobrescrita = async () => {
        try {
            await api.post('/overwrite', {
                fk_id_did: selectDid,
                fk_id_prefix: selectPrefixo,
                priority: inputPriority,
            })

            toast.success('Sobrescrita Adicionada')
            buscarSobrescritas()
        } catch (error) {
            if(error.response){
                toast.error(error.response.data.error)
            }else{
                toast.error(error.message)
            }
        }
    }

    const alterarSobrescrita = async (id) => {
        try {
            await api.put(`/overwrite/${id}`, {
                fk_id_did: selectDid,
                fk_id_prefix: selectPrefixo,
                priority: inputPriorityEditar,
            })

            toast.success('Sobrescrita Alterada com Sucesso')
            buscarSobrescritas()
        } catch (error) {
            if(error.response){
                toast.error(error.response.data.error)
            }else{
                toast.error(error.message)
            }
        }
    }

    const removerSobrescrita = async (id) => {
        try {
            await api.delete(`/overwrite/${id}`)

            toast.success('Sobrescrita Removida com Sucesso')
            buscarSobrescritas()
        } catch (error) {
            if(error.response){
                toast.error(error.response.data.error)
            }else{
                toast.error(error.message)
            }
        }
    }

    useEffect(() => {
        buscarSobrescritas()
    }, [])

    return (
            <div>
                <br />
                <div className="container">
                    <button type="button" className="btn btn-primary" data-bs-toggle="modal" data-bs-target="#modalAddRoute">
                        <FaPlus /> Adicionar
                    </button>
                    <table className="table">
                    <thead>
                        <tr>
                            <th scope="col">#</th>
                            <th scope="col">DID</th>
                            <th scope="col">Prefixo</th>
                            <th scope="col">Prioridade</th>
                            <th scope="col">Adicionado</th>
                            <th scope="col">Atualizado</th>
                            <th scope="col">Ações</th>
                        </tr>
                    </thead>
                    <tbody>
                        {overwrites && overwrites.map(overwrite => {
                            return (
                                <tr key={overwrite.id}>
                                    <th scope="row">{overwrite.id}</th>
                                    <td>{overwrite.Did.did}</td>
                                    <td>{overwrite.Prefix.prefix} - {overwrite.Prefix.description}</td>
                                    <td>{overwrite.priority}</td>
                                    <td>{new Date(overwrite.created_at).toLocaleString()}</td>
                                    <td>{new Date(overwrite.updated_at).toLocaleString()}</td>
                                    <ButtonContainer>
                                        <div>
                                            <button type="button" className="btn btn-danger btn-sm" data-bs-toggle="modal" data-bs-target="#modalRemoverSobrescrita" onClick={() => {setOverwriteRemover(overwrite)}}>Remover</button>
                                            <button type="button" className="btn btn-warning btn-sm" data-bs-toggle="modal" data-bs-target="#modaAlterarSobrescrita" onClick={() => {
                                                setOverwriteEditar(overwrite)
                                                setInputPriorityEditar(overwrite.priority)

                                                setSelectDid(overwrite.Did.id)
                                                setSelectPrefixo(overwrite.Prefix.id)
                                            }}>Editar</button>
                                        </div>
                                    </ButtonContainer>
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
                </div>

                {/* Modal Adicionar*/}
                <div className="modal fade" id="modalAddRoute" tabIndex="-1" aria-labelledby="modalAddPrefixoLabel" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="modalAddPrefixoLabel">Adicionar Sobrescrita</h5>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body">
                        <form>
                            <fieldset>
                                <div className="mb-3">
                                    <label htmlFor="SelectDID" className="form-label">DID</label>
                                    <select id="SelectDID" class="form-select" placeholder="Operadora" value={selectDid} onChange={e => setSelectDid(e.target.value)}>
                                        <option>Selecione uma Operadora</option>
                                        {dids && dids.map(did => {
                                            return (
                                                <option value={did.id}>{did.did}</option>
                                            )
                                        })}
                                    </select>

                                    </div>
                                <div className="mb-3">
                                    <label htmlFor="SelectPrefixo" className="form-label">Prefixo</label>
                                    <select id="SelectPrefixo" class="form-select" placeholder="Operadora" value={selectPrefixo} onChange={e => setSelectPrefixo(e.target.value)}>
                                        <option>Selecione um Prefixo</option>
                                        {prefixes && prefixes.map(pr => {
                                            return (
                                                <option value={pr.id}>{pr.prefix}</option>
                                            )
                                        })}
                                    </select>
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="SelectPrioridade_1" className="form-label">Prioridade</label>
                                    <select id="SelectPrioridade_1" class="form-select" placeholder="Operadora" value={inputPriority} onChange={e => setInputPriority(e.target.value)}>
                                        {[1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20].map(item => {
                                            return (
                                                <option value={item}>{item}</option>
                                            )
                                        })}
                                    </select>
                                </div>
                            </fieldset>
                        </form>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Fechar</button>
                        <button type="button" className="btn btn-primary" onClick={() => {adicionarSobrescrita()}}>Adicionar</button>
                    </div>
                    </div>
                </div>
                </div>

                {/* Modal Alterar*/}
                <div className="modal fade" id="modaAlterarSobrescrita" tabIndex="-1" aria-labelledby="modalAddPrefixoLabel" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="modalAddPrefixoLabel">Alterar Sobrescrita</h5>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body">
                        <form>
                            <fieldset>
                                <div className="mb-3">
                                    <label htmlFor="SelectOperadora" className="form-label">DID</label>
                                    <select id="SelectOperadora" class="form-select" placeholder="DID" value={selectDid} onChange={e => setSelectDid(e.target.value)}>
                                        <option>Selecione uma Operadora</option>
                                        {dids && dids.map(did => {
                                            return (
                                                <option value={did.id}>{did.did}</option>
                                            )
                                        })}
                                    </select>

                                </div>
                                <div className="mb-3">
                                    <label htmlFor="SelectPrefixo" className="form-label">Prefixo</label>
                                    <select id="SelectPrefixo" class="form-select" placeholder="Operadora" value={selectPrefixo} onChange={e => setSelectPrefixo(e.target.value)}>
                                        <option>Selecione um Prefixo</option>
                                        {prefixes && prefixes.map(pr => {
                                            return (
                                                <option value={pr.id}>{pr.prefix}</option>
                                            )
                                        })}
                                    </select>
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="SelectPrioridade_1" className="form-label">Prioridade</label>
                                    <select id="SelectPrioridade_1" class="form-select" placeholder="Operadora" value={inputPriorityEditar} onChange={e => setInputPriorityEditar(e.target.value)}>
                                        {[1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20].map(item => {
                                            return (
                                                <option value={item}>{item}</option>
                                            )
                                        })}
                                    </select>
                                </div>
                            </fieldset>
                        </form>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Fechar</button>
                        <button type="button" className="btn btn-warning" onClick={() => {alterarSobrescrita(overwriteEditar.id)}}>Alterar</button>
                    </div>
                    </div>
                </div>
                </div>

                {/* Modal Remover */}
                <div className="modal fade" id="modalRemoverSobrescrita" tabIndex="-1">
                    <div className="modal-dialog">
                        <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Remover {overwriteRemover?.Did?.did} - {overwriteRemover?.Prefix?.description}?</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <p>Confirmar a remoção da Sobrescrita: {overwriteRemover.priority} {overwriteRemover?.Did?.did} {overwriteRemover?.Prefix?.description}</p>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Fechar</button>
                            <button type="button" className="btn btn-danger" data-bs-dismiss="modal" onClick={() => {removerSobrescrita(overwriteRemover.id)}}>Remover</button>
                        </div>
                        </div>
                    </div>
                </div>

            </div>
    )
}

export default Overwrite;
