import styled from "styled-components"

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`

export const Item = styled.div`
  border: 2px solid #adb5bd;
  border-radius: 10px;
  min-width: 500px;
  padding: 10px;
  margin: 5px;

  @media (max-width: 600px) {
    min-width: 100%;
  }
  @media (max-width: 360px) {
    min-width: 100%;
  }

  display: flex;
  align-items: center;
  justify-content: space-between;

  span {
    @media (max-width: 600px) {
      font-size: 8pt;
    }
    @media (max-width: 360px) {
      font-size: 8pt;
    }
  }

  a {
    span {
      @media (max-width: 600px) {
        font-size: 8pt;
      }
      @media (max-width: 360px) {
        font-size: 8pt;
      }
    }
  }

  div {
    button {
      width: 120px;
    }
  }
`
