import styled from 'styled-components'

export const Form = styled.form`
    legend {
        margin-top: 25px;
        margin-bottom: 50px;
    }
`;

export const DataContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;

    div.react-datepicker-wrapper {
        width: 250px !important;
    }
`;